/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import { Avatar, Button } from "antd"
import cn from "classnames"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"

import defUserIcon from "../../../resources/images/def-user-icon.svg"
import pickLogo from "../../../resources/images/logo/pick-logo.png"
import CommonStyle from "../../../resources/styles/common.module.css"
import Dropdown from "./components/Dropdown"
import Styles from "./index.module.css"

const Header = (props: any) => {
  const defaultNavs = [
    {
      url: "/Pick/PickList",
      name: "My Picks",
      active: false,
    },
    {
      url: "/Pick/RequestList",
      name: "My Requests",
      active: false,
    },
    // {
    //   url: 'Drops',
    //   name: 'Drops',
    //   active: false,
    // },
  ]

  const [navs, setNavs] = useState(defaultNavs)
  const [userInfo, setUserInfo] = useState(Object)

  useEffect(() => {
    setNavsFunc(window.location.pathname)
    setUserInfo(props.userInfo)
  }, [window.location.href, props.userInfo])

  const navigate = useNavigate()

  const handleNavigate = (url: any) => {
    const { userInfo } = props
    navigate(`${url}`)
    setNavsFunc(url)
  }

  const handleNavHome = () => {
    navigate("/")
  }

  const setNavsFunc = (url: any) => {
    let finalUrl: any = null
    if (!url.endsWith("/")) {
      url += "/"
    }

    switch (url.toLowerCase()) {
      case "/pick/":
      case "/pick/picklist/":
        finalUrl = "/Pick/PickList"
        break
      case "/pick/requestlist/":
      case "/pick/requestdetail/":
        // case '/pick/successproducts/':
        finalUrl = "/Pick/RequestList"
        break
      case "/pick/newrequest/":
        finalUrl = "/Pick/NewRequest"
        break
      default: {
        finalUrl = url
      }
    }

    let newNavs = JSON.parse(JSON.stringify(defaultNavs))
    newNavs = newNavs.map((nav: any, index: any) => {
      if (nav.url == finalUrl) {
        return {
          ...nav,
          active: true,
        }
      } else {
        return nav
      }
    })
    setNavs(newNavs)
  }

  const handleShowShareDialog = () => {
    // trigger custom show share dialog event
    document.dispatchEvent(new Event("showShareDialog"))
  }

  const gotoShop = () => {
    if (props.userInfo && props.userInfo.shopify_collection_link) {
      window.open(props.userInfo.shopify_collection_link)
    } else {
      navigate("/Pick/SuccessProducts")
    }
  }

  return (
    <>
      <div className={cn(Styles.headerView, props.className)}>
        <div className={Styles.mobileUserBar}>
          <div className={Styles.mobileUserBarInfo} onClick={() => navigate("/Pick/Account")}>
            <Avatar src={props?.userInfo?.photo} icon={<span className="iconfont icon-accountline" />} />
            <h1>{props?.userInfo?.name || props?.userInfo?.username || "-"}</h1>
          </div>

          <div className={Styles.mobileUserBarActions}>
            <Button
              className={Styles.shareBtn}
              shape="circle"
              size="large"
              icon={<span className="iconfont icon-share1" />}
              onClick={handleShowShareDialog}
            />
          </div>
        </div>

        <div className={Styles.leftView}>
          <img className={Styles.logo} src={pickLogo} onClick={handleNavHome} />
          {props.showButtons != false && (
            <>
              {navs.map((nav: any, index: any) => (
                <div
                  key={index}
                  className={`${Styles.navItem} ${CommonStyle.t8} ${nav.active && Styles.active}`}
                  onClick={handleNavigate.bind(this, nav.url)}
                >
                  <span>{nav.name}</span>
                  {/*
                 nav.name == 'My Requests' && <Badge count={1} offset={[2, -5]} />
              */}
                </div>
              ))}
              <div className={`${Styles.navItem} ${CommonStyle.t8}`}>
                <Button type="link" onClick={gotoShop} className={Styles.shopHref}>
                  My Drops
                </Button>
              </div>
            </>
          )}
        </div>
        {props.showButtons != false ? (
          <div className={Styles.rightView}>
            <div className={Styles.userNav}>
              <span className={`${Styles.navItem} ${CommonStyle.t8}`} style={{ marginRight: 0 }}>
                <img className={Styles.userIcon} src={_.get(props.userInfo, "photo") || defUserIcon} />
              </span>

              <div className={Styles.dropDown}>
                <Dropdown userInfo={props.userInfo} />
              </div>
            </div>
          </div>
        ) : (
          <div
            className={Styles.downLoad}
            onClick={() => {
              window.open("https://chrome.google.com/webstore/detail/a-kiwi-pick/cmgglgjgcgmhjcmlkbcfannfenmhfmdh")
            }}
          >
            <Button className={Styles.downLoadBtn}>Download Plugin</Button>
          </div>
        )}
      </div>
      <div className={Styles.headerPlaceHolder}></div>
    </>
  )
}

function mapStateToProps(state: any) {
  return {
    userInfo: state.app.currentUser ? state.app.currentUser.user : null,
  }
}

export default connect(mapStateToProps)(Header)
