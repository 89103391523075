import * as actions from "./constant"

const initialState = {
  init: {},
  currentUser: null,
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case actions.INIT:
      return {
        ...state,
        init: action.payload,
      }
    case actions.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      }
    case actions.RECEIVED_MESSAGES:
      return {
        ...state,
        messages: action.payload,
      }

    case actions.UPDATE_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      }
    default:
      return state
  }
}
