import { currencyObj, destinationCountryObj } from "../../../resources/options/index"
import CommonStyle from "../../../resources/styles/common.module.css"
import Styles from "./index.module.css"

const FormInfoLine = (props: any) => {
  const { label, value } = props

  return (
    <div className={Styles.item}>
      <span className={`${CommonStyle.t4} ${CommonStyle.tb}`}>{label}</span>
      <span>{value || "-"}</span>
    </div>
  )
}

const RequestFormInfo = (props: any) => {
  const { requestFormInfo } = props
  const { order_type, pick_type, plan_sell, quantity, destination_country, plan_sell_currency, product_category } = requestFormInfo
  const productUrl = requestFormInfo?.pick?.item?.source_url

  return (
    <div className={`${CommonStyle.card} ${Styles.requestFormView}`}>
      {/* <FormInfoLine label={'Sourcing Origin'} value={sourcing_origin} /> */}
      <FormInfoLine label={"Order Type"} value={order_type} />
      <FormInfoLine label={"Plan to sell at (per unit)"} value={`${currencyObj[plan_sell_currency] || "$"}${plan_sell}`} />
      <FormInfoLine label={"Est. Quanitity"} value={`${quantity || "-"} units`} />
      <FormInfoLine label={"Destination Country"} value={destination_country && destinationCountryObj[destination_country]} />
      <FormInfoLine label={"Product Category"} value={product_category} />
      {pick_type == "ITEM" ? (
        <FormInfoLine
          label={"Reference URL"}
          value={
            productUrl && (
              <a href={productUrl} target="_blank" rel="noreferrer">
                {new URL(productUrl).hostname}
              </a>
            )
          }
        />
      ) : null}
    </div>
  )
}

export default RequestFormInfo
