import cn from "classnames"
import React from "react"

import Styles from "./index.module.css"

export interface ContainerProps {
  className?: string
  children: React.ReactNode
}

const Container = (props: ContainerProps) => {
  return <div className={cn(Styles.mainBg, props.className)}>{props.children}</div>
}

export default Container
