import "./index.css"

import { Input } from "antd"
import React from "react"

import CommonStyle from "../../../../resources/styles/common.module.css"
import Styles from "./index.module.css"

const { TextArea } = Input

class Urls extends React.Component<any, any> {
  urlR = /(https?:\/\/)?([-\w]+\.)+[-\w]+(\/[-\w .,~～∼/?%&=]*)?/i
  placeHolder =
    "https://www.aliexpress.com/item/4001308391459.html?srcSns=........\n" +
    "https://www.revolve.com/anine-bing-oakley-jacket/dp/ANIN-WO.......\n" +
    "any additional links..."

  constructor(props: any) {
    super(props)

    this.state = {
      urls: [],
      errorMsgShow: false,
      errorMsgStatus: null,
      showErrorMsgObj: {
        0: "Please enter at least one URL",
        1: "Please fill in a valid URL",
      },
    }
  }

  urlTextAreaChange = (e: any) => {
    const allUrls = e.target.value.split("\n")
    const urls = []

    for (const url of allUrls) {
      const trimUrl = url.trim()
      if (!trimUrl) {
        continue
      }

      if (url.match(this.urlR)) {
        urls.push({ link: trimUrl, valid: true })
      } else {
        urls.push({ link: trimUrl, valid: false })
      }
    }

    this.setState({
      urls,
    })
  }

  getSubmitUrls = () => {
    return this.state.urls.filter((url: any) => url.valid)
  }

  render() {
    // const { urls, showErrorMsgObj, errorMsgStatus, errorMsgShow } = this.state
    const { presetUrls } = this.props
    const validUrlCount = this.getSubmitUrls().length

    return (
      <div className={`${Styles.UrlsMain} UrlsMain`}>
        <div className={CommonStyle.column}>
          <h3>
            Add URL(s) <span className={Styles.require}>*</span>
          </h3>
          {validUrlCount > 0 ? <span className={`${CommonStyle.t6} ${CommonStyle.tb}`}>{validUrlCount} URL(s)</span> : null}
        </div>
        <TextArea
          rows={4}
          placeholder={this.placeHolder}
          defaultValue={presetUrls}
          className={Styles.urlTextArea}
          onChange={this.urlTextAreaChange}
        />

        {/* urls */}
        {/* <div>
          {urls.map((url: any, index: any) =>
            <div className={Styles.urlItem} key={index}>
              <span className={Styles.urlIndex}>
                {index + 1}
              </span>
              <Input value={url.link} placeholder="Enter or Paste URLs" onChange={this.enterChange.bind(this, index)}></Input>
              {
                urls.length > 1 && <span onClick={this.upsterUrls.bind(this, url)} className={`${Styles.urlRemoveIcon} iconfont icon-exit`} />
              }
            </div>
          )}
          <p className={Styles.urlErrMsg} >
            {errorMsgShow && showErrorMsgObj[errorMsgStatus]}
          </p>
          <Button onClick={this.upsterUrls.bind(this, null)} className={`${Styles.addUrlBtn}`}>+ Add URL</Button>
          <p className={`${Styles.tip}`}>Or hit enter to keep adding links</p>
        </div> */}
      </div>
    )
  }

  upsterUrls = (item: any) => {
    let { urls } = this.state
    this.setState({
      errorMsgShow: false,
    })
    if (item) {
      urls = urls.filter((url: any) => url !== item)
    } else {
      if (this.validates()) {
        urls.push({
          link: "",
        })
      }
    }
    this.setState({
      urls,
    })
  }

  validates = () => {
    const { urls } = this.state
    let flag = true
    let errorMsgStatus = null
    this.setState({
      errorMsgShow: false,
    })
    const urlReg = /(https?:\/\/)?([-\w]+\.)+[-\w]+(\/[-\w .,~～∼/?%&=]*)?/i
    for (const item of urls) {
      if (!item.link) {
        errorMsgStatus = 0
        flag = false
      } else if (!item.link.match(urlReg)) {
        errorMsgStatus = 1
        flag = false
      }
    }
    if (!flag) {
      this.setState({
        errorMsgShow: true,
        errorMsgStatus,
      })
    }
    return flag
  }

  enterChange = (index: any, e: any) => {
    const { urls } = this.state
    urls[index].link = e.target.value

    console.log(urls)
    this.setState({
      urls,
    })

    this.validates()
  }
}

export default Urls
