import { Button, Spin } from "antd"
import cn from "classnames"
import _ from "lodash"
import React from "react"
import { Link } from "react-router-dom"

import Container from "@/components/Common/Container"
import Footer from "@/components/Common/Footer"
import ProductsDialog from "@/components/Common/ProductsDialog"
import { getRequests } from "@/services/pick"

import Empty from "./components/Empty"
import RequestItem from "./components/RequestItem"
import SearchBar from "./components/SearchBar"
import Styles from "./index.module.css"

class RequestList extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      requests: [],
      loading: true,
      filterArgs: {
        search: undefined,
        status: undefined,
        sort_by: undefined,
        page: 1,
        per_page: 20,
      },

      allNum: 0,
      canCurlFlag: true,
      productsDialogVisible: false,
      currentViewProducts: [],
    }
  }

  render() {
    const { filterArgs, loading, requests, productsDialogVisible, currentViewProducts } = this.state
    const isEmptyPage = !loading && _.isEmpty(requests)

    return (
      <Container className={cn({ [Styles.emptyPage]: isEmptyPage })}>
        <SearchBar upsterRequestListArgs={this.upsterRequestListArgs} showButton={true} />

        <div className={Styles.requestView}>
          {requests.map((r: any, index: any) => (
            <RequestItem key={index} request={r} onViewProduct={this.handleShowProductsDialog} />
          ))}
        </div>

        {isEmptyPage && <Empty hasFilter={filterArgs.search || filterArgs.status} />}

        {loading ? (
          <Spin tip="Loading...">
            <div className={Styles.loading}></div>
          </Spin>
        ) : null}
        <Footer />

        {/* mobile `New Request` floating icon button */}
        <Link to="/Pick/NewRequest">
          <Button className={Styles.mobileNewRequestBtn} shape="circle" icon={<span className="iconfont icon-plus" />} size="large" />
        </Link>

        {/* multiple products dialog */}
        <ProductsDialog
          visible={productsDialogVisible}
          title="Successfully Sourced Products"
          products={currentViewProducts}
          onClose={this.handleHideProductsDialog}
        />
      </Container>
    )
  }

  upsterRequestListArgs = (arg: any) => {
    const { filterArgs } = this.state
    const newFilterArgs = Object.assign({}, filterArgs, {
      ...arg,
      page: 1,
    })

    console.log(newFilterArgs)
    this.setState(
      {
        filterArgs: newFilterArgs,
      },
      () => {
        this._getRequests()
      }
    )
  }

  _getRequests = async () => {
    const { filterArgs } = this.state
    this.setState({
      loading: true,
    })
    return getRequests(filterArgs).then((res) => {
      console.log("_getRequests")
      console.log(res)
      if (res) {
        this.setState({
          requests: res.items,
          allNum: res.total,
          loading: false,
        })
      }
    })
  }

  moveToBrowserBottom = () => {
    let timerForDebounce: any = null // 为了防抖添加的timer
    window.onscroll = () => {
      if (timerForDebounce) clearTimeout(timerForDebounce)
      const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
      // gap是为了计算偏差，有时候会有1px的偏差值
      const gap = Math.ceil(document.documentElement.clientHeight + scrollTop) - document.documentElement.scrollHeight
      if (document.documentElement.scrollHeight === Math.ceil(document.documentElement.clientHeight + scrollTop) || gap === 1) {
        timerForDebounce = setTimeout(() => {
          console.log("触底了")
          this.concatData()
        }, 200)
      }
    }
  }

  concatData = () => {
    this.setState({
      loading: true,
    })
    const { filterArgs, allNum, canCurlFlag } = this.state

    const { per_page, page } = filterArgs
    const maxPage = Math.ceil(allNum / per_page)
    if (page > maxPage - 1) {
      console.log("没有更多了")
      this.setState({
        loading: false,
      })
    } else {
      if (canCurlFlag) {
        const newFilterArgs = Object.assign({}, filterArgs, {
          page: this.state.filterArgs.page + 1,
        })
        this.setState(
          {
            canCurlFlag: false,
            page: this.state.page + 1,
            filterArgs: newFilterArgs,
          },
          () => {
            const reqBody = this.state.filterArgs
            getRequests(reqBody).then((data) => {
              if (data) {
                const newItems = this.state.requests.concat(data.items)
                this.setState({
                  requests: newItems,
                  canCurlFlag: true,
                  loading: false,
                })
              }
            })
          }
        )
      }
    }
  }

  handleShowProductsDialog = (request: any) => {
    this.setState({
      productsDialogVisible: true,
      currentViewProducts: request?.shopify_products ?? [],
    })
  }

  handleHideProductsDialog = () => {
    this.setState({ productsDialogVisible: false })
  }

  async componentDidMount() {
    await this._getRequests()
    this.moveToBrowserBottom()
  }

  componentWillUnmount() {
    window.onscroll = null
  }
}

export default RequestList
