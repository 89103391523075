import { Modal } from "antd"

import CommonStyle from "../../../resources/styles/common.module.css"

const Dialog = (props: any) => {
  const { children, title, visible, closeModal, className, style, width, ...restProps } = props
  return (
    <>
      <Modal width={width} footer={props.footer || null} visible={visible} closable={false} className={className} style={style} {...restProps}>
        {/* modal title */}
        <div className={CommonStyle.modalNav}>
          <span className={`${CommonStyle.modalNavTitle}`} title={title}>
            <img
              className={`${CommonStyle.modalNaviImage}`}
              src="https://pickkiwi.s3.amazonaws.com/upload/ly1sYz9aM986CwIOOCQ6Kwxx2vSxJK5eOia16D8x6nLO7cWTDTk7jKSwCl3bj-Ku2AGKSd7l"
              alt=""
            />

            {title}
          </span>
          <span className={`${CommonStyle.modalNavClose}`}>
            <span onClick={closeModal} className={`iconfont icon-exit ${CommonStyle.modalNavCloseIcon}`}></span>
          </span>
        </div>

        {/* modal body */}
        <div style={{ paddingTop: 30 }}>{children}</div>
      </Modal>
    </>
  )
}

export default Dialog
