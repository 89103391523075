import { Button } from "antd"
import React from "react"
import { connect } from "react-redux"

import Container from "@/components/Common/Container"
import ShareDialog from "@/components/Common/ShareDialog"

import OrganizeBoard from "./components/OrganizeBoard"
import ProductList from "./components/ProductList"
import SearchBar from "./components/SearchBar"
import UserTag from "./components/UserTag"
import Styles from "./index.module.css"

const insertParam = (key: string, value: string) => {
  key = encodeURIComponent(key)
  value = encodeURIComponent(value)

  let kvp = window.location.search.substr(1).split("&")
  if (kvp[0] === "") {
    const path = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + key + "=" + value
    window.history.pushState({ path: path }, "", path)

  } else {
    let i = kvp.length
    let x
    while (i--) {
      x = kvp[i].split("=")

      if (x[0] === key) {
        x[1] = value
        kvp[i] = x.join("=")
        break
      }
    }

    if (i < 0) {
      kvp[kvp.length] = [key, value].join("=")
    }

    const refresh = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + kvp.join("&")
    window.history.pushState({ path: refresh }, "", refresh)
  }
}

class PickListIndex extends React.Component<any, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      filterArgs: {
        search: undefined,
        status: undefined,
        sort_by: undefined,
        tag_ids: undefined,
      },
      shareDialogVisible: false,
      productListItems: null,
    }
  }

  render() {
    const { filterArgs, shareDialogVisible, productListItems } = this.state
    return (
      <Container>
        <div className={Styles.content}>
          <div className={Styles.searchView}>
            <div className={Styles.searchBar}>
              <SearchBar upsterPickListArgs={this.upsterPickListArgs} />
            </div>
            <div className={Styles.actions}>
              <Button
                className={Styles.shareBtn}
                shape="circle"
                size="large"
                icon={<span className="iconfont icon-share1" />}
                onClick={this.handleShowShareDialog}
              />

              <OrganizeBoard />
            </div>
          </div>
          <UserTag upsterPickListArgs={this.upsterPickListArgs} />
          <ProductList filterArgs={filterArgs} onLoad={this.handleProductListLoad} />
        </div>

        <ShareDialog
          visible={shareDialogVisible}
          url={`${location.origin}/SharePicks/${this.props?.user?.id}${window.location.search}`}
          images={productListItems?.map?.((item: any) => (item?.pick_type === "ITEM" ? item?.item?.cover_image : item?.images?.[0]))}
          onClose={this.handleHideShareDialog}
        />
      </Container>
    )
  }

  upsterPickListArgs = (arg: any) => {
    const { filterArgs } = this.state
    const newFilterArgs = Object.assign({}, filterArgs, {
      ...arg,
    })

    if (newFilterArgs.tag_ids) {
      insertParam("tag_ids", newFilterArgs.tag_ids.join(","))
    }
    this.setState({
      filterArgs: newFilterArgs,
    })
  }

  handleShowShareDialog = () => {
    this.setState({ shareDialogVisible: true })
  }

  handleHideShareDialog = () => {
    this.setState({ shareDialogVisible: false })
  }

  handleProductListLoad = (items: any[]) => {
    this.setState({ productListItems: items })
  }

  componentDidMount() {
    // add class names to html element
    document.documentElement.classList.add("page-pick-list", "show-header-share-button")

    // listen custom show share dialog event
    document.addEventListener("showShareDialog", this.handleShowShareDialog)
  }

  componentWillUnmount() {
    // remove class names from html element
    document.documentElement.classList.remove("page-pick-list", "show-header-share-button")

    // remove custom show share dialog event
    document.removeEventListener("showShareDialog", this.handleShowShareDialog)
  }
}

function mapStateToProps(state: any) {
  return {
    user: state.app.currentUser.user,
  }
}

export default connect(mapStateToProps)(PickListIndex)
