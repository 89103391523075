import React from "react"

import Swiper from "../../Common/Swiper"

const ProductImages = (props: any) => {
  const { productImages, onChange } = props
  return (
    <div>
      <Swiper images={productImages} onChange={onChange} />
    </div>
  )
}

export default ProductImages
