import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import { Carousel, Checkbox, Form, Image, Input, InputNumber, Radio, Select } from "antd"
import _ from "lodash"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

import BaseButton from "@/components/Common/BaseButton"
import Dialog from "@/components/Common/Dialog/index"
import { currencyOpts, destinationCountryOpts, orderTypeOpts, productCategoryOpts } from "@/resources/options"
import CommonStyles from "@/resources/styles/common.module.css"
import { addRequest } from "@/services/pick"
import { convertToUSD } from "@/utils/currency"

import Styles from "./index.module.css"
const { Option } = Select

const RequestForm = (props: any) => {
  const [requestedDialogVisible, setRequestedDialogVisible] = useState(false)

  const [requestedStatus, setRequestedStatus] = useState(-1)

  const [newReqId, setNewReqId] = useState("")
  const { product, formRef, from } = props

  const navigate = useNavigate()

  const onFinish = (values: any) => {
    const { from } = props

    // 在创建request时 从similar product发起的 需要传递item_id
    // 除此之外发起的request 需要传递pick_id
    const reqBody = {
      pick_id: product.pick_id,
      ...values,
      ...values.plan,
    }

    if (from === "similarProducts") {
      reqBody.item_id = product.item_id
      delete reqBody.pick_id
    }
    delete reqBody.plan

    addRequest(reqBody).then((res) => {
      document.querySelectorAll(".ant-modal-content")[0].classList.add("hide")
      console.log("request added", res)

      if (res) {
        setRequestedStatus(0)
        setRequestedDialogVisible(true)
        setNewReqId(res.id)
        // onFinish 是在pick 列表 发送request后 跳转到request详情的动作 需要传递一个参数 现在取消掉了
        // if (onFinish) {
        //   onFinish(res.id)
        // } else {
        //   setRequestedStatus(0)
        //   setRequestedDialogVisible(true)
        //   // closeModal(res.id)
        // }
      } else {
        // error
        setRequestedStatus(1)
        setRequestedDialogVisible(true)
      }
    })
  }

  const _closeModel = () => {
    setRequestedDialogVisible(false)
    props.closeModal(newReqId, product)
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo)
  }

  const originPrice = product.price ? Number(product.price).toFixed(2) : null
  // const images = product.cover_image || product.images ? product.images[0] : ''
  const images = product?.images && !_.isEmpty(product?.images) ? product?.images : !_.isEmpty(product.cover_image) ? [product.cover_image] : []

  return (
    <div>
      <div className={`requestFormMain ${Styles.requestFormMain}`}>
        <div className={Styles.productBasic}>
          {!_.isEmpty(images) && (
            <Image.PreviewGroup>
              <Carousel dots={false} arrows={true} prevArrow={<LeftOutlined />} nextArrow={<RightOutlined />}>
                {images?.map((src: string) => (
                  <Image key={src} className={Styles.productImg} src={src} preview={{ mask: "Preview" }} />
                ))}
              </Carousel>
            </Image.PreviewGroup>
          )}

          {/* 从similarProducts发起的requset 不需要显示标题和价格 */}
          {from === "similarProducts" ? (
            <></>
          ) : (
            <>
              <p className={`${Styles.productTitle} ${CommonStyles.nowrapText}`}>{product.title}</p>
              {originPrice ? <p className={Styles.price}>Original Price: ${convertToUSD(originPrice, product.currency).amount}</p> : null}
            </>
          )}
        </div>

        <div className={Styles.form}>
          <Form ref={formRef} name="basic" layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <div className={`${CommonStyles.column} ${Styles.orderTypeRow}`}>
              <Form.Item label="Order Type" name="order_type" initialValue="Wholesale" style={{ width: "60%" }}>
                <Radio.Group size="large">
                  {orderTypeOpts.map((opt: any, index: any) => (
                    <Radio.Button key={index} value={opt.value}>
                      {opt.label}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Form.Item>
              <Form.Item label="Estimated Quantity" name="quantity" initialValue={1} className={Styles.quantityInput}>
                <InputNumber min={1} step={1} precision={0} />
              </Form.Item>
            </div>

            <Form.Item label="Plan to sell at (per unit)">
              <Input.Group>
                <Form.Item
                  name={["plan", "plan_sell"]}
                  noStyle
                  // initialValue={originPrice ? convertToUSD(originPrice, product.currency).amount : undefined}
                  rules={[{ required: false, message: "desiredCost is required" }]}
                >
                  <InputNumber className={Styles.costInput} min={0} />
                </Form.Item>

                <Form.Item
                  name={["plan", "plan_sell_currency"]}
                  noStyle
                  initialValue={"USD"}
                  rules={[{ required: false, message: "currency is required" }]}
                >
                  <Select style={{ width: 100, marginLeft: 10 }}>
                    {currencyOpts.map((opt: any, index: any) => (
                      <Option key={index} value={opt.value}>
                        {opt.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Input.Group>
            </Form.Item>

            <Form.Item
              label="Destination Country"
              name="destination_country"
              rules={[{ required: false, message: "Please select Destination Country" }]}
              initialValue={"US"}
            >
              <Select>
                {destinationCountryOpts.map((opt: any, index: any) => (
                  <Option key={index} value={opt.value}>
                    {opt.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Product Category" name="product_category" initialValue={"Apparel"}>
              <Select defaultValue={"Apparel"}>
                <Option value={""}>Select</Option>
                {productCategoryOpts.map((opt: any, index: any) => (
                  <Option key={index} value={opt.value}>
                    {opt.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="accept_similar" valuePropName="checked" initialValue={true} className={Styles.checkBoxRow}>
              <Checkbox>Accept similar products</Checkbox>
            </Form.Item>

            <Form.Item wrapperCol={{ md: { offset: 8, span: 16 } }} style={{ margin: 0 }}>
              <BaseButton type="primary" color={"black"} shape={"round"} className={Styles.submitBtn} htmlType="submit">
                Submit Request
              </BaseButton>
            </Form.Item>
          </Form>
        </div>
      </div>

      {/* requestd dialog (success || error) */}
      {/* <RequestedDialog /> */}
      <Dialog width={400} style={{ top: 20 }} title="Request Product" visible={requestedDialogVisible} maskClosable closeModal={_closeModel}>
        <div className={Styles.requestedContainer}>
          {requestedStatus == 0 ? (
            // success
            <div>
              <div className={Styles.requestedImg}>
                <img src={images[0]} width="95%" alt="" />
                <span className={`iconfont icon-right ${Styles.requestedIcon}`} />
              </div>

              <div className={Styles.requestdTit}>
                <h3>Request Submited !</h3>
                <p>
                  We'll get back to you within 48 hours. <br />
                  Check back soon!
                </p>
              </div>
              <div className={Styles.requestedSubmit}>
                <BaseButton
                  onClick={() => {
                    navigate("/pick/RequestList")
                  }}
                  type="primary"
                  color={"black"}
                  shape={"round"}
                  className={Styles.requestedBtn}
                  htmlType="submit"
                >
                  To My Requests
                </BaseButton>
              </div>
            </div>
          ) : (
            // error
            <div>
              <div className={Styles.requestedIcon2Box}>
                <span className={`iconfont icon-stop ${Styles.requestedIcon2}`}></span>
              </div>

              <h3 className={Styles.requestdTit2}>Request Failed</h3>
              <p>
                We weren't able to process your request. <br />
                Try refreshing the page and requesting again.
              </p>

              <div className={Styles.requestedSubmit}>
                <BaseButton onClick={_closeModel} type="primary" color={"black"} shape={"round"} className={Styles.requestedBtn2} htmlType="submit">
                  Contact Support
                </BaseButton>
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </div>
  )
}

export default RequestForm
