import { Button } from "antd"
import cn from "classnames"
import React from "react"

import styles from "./css/Empty.module.css"

export interface Props {
  /** The component class name */
  className?: string

  /** The component inline style */
  style?: React.CSSProperties
}

/**
 * Empty list state tips
 */
function Empty({ className, style, ...restProps }: Props): JSX.Element {
  return (
    <div className={cn(styles.container, className)} style={style} {...restProps}>
      {/* Display on PC size */}
      <div className={styles.pcContent}>
        <p className={styles.tips}>To start adding picks to your page, download the KiwiPick plugin availible for free on the Google Chrome store!</p>
        <Button
          type="primary"
          className={styles.downloadButton}
          size="large"
          onClick={() => window.open("https://chrome.google.com/webstore/detail/a-kiwi-pick/cmgglgjgcgmhjcmlkbcfannfenmhfmdh")}
        >
          Download Here
        </Button>
        <dl className={styles.featureList}>
          <dt>Pick!</dt>
          <dd>Pick images from anywhere, and product detail pages from supported sites.</dd>
          <dt>Request!</dt>
          <dd>Request Drops for any product starting from your picks, or start from scratch.</dd>
          <dt>Buy!</dt>
          <dd>If we’ve found sourcing for a product, you’re ready to buy it for your store!</dd>
        </dl>
        <p className={styles.tips}>
          {/* TODO: set link url */}
          <a href="#">Here‘s a list</a> of the sites we support
        </p>
      </div>

      {/* Display on Mobile size */}
      <div className={styles.mobileContent}>
        <p className={styles.tips}>
          <i>😳</i>
          <strong>Your picks page is empty</strong>
          You can pick items by tapping on share for <br />
          photos or products, then scrolling down to <br />
          “Pick to Kiwi”.
        </p>

        {/* Pick to Kiwi screenshot */}
        <div className={styles.screenshot} />

        <p className={styles.tips}>
          Picks created through our google chrome <br />
          plugin on your desktop web browser will also <br />
          be saved here.
        </p>
      </div>
    </div>
  )
}

export default Empty
