import { Button, Modal } from "antd"
import cn from "classnames"
import { saveAs } from "file-saver"
import isMobile from "ismobilejs"
import _ from "lodash"
import React, { useEffect, useMemo, useState } from "react"
import { connect } from "react-redux"

import Common from "../../../utils/common"
import styles from "./index.module.css"

// is browser support download feature
const isSupportDownload = "download" in document.createElement("a")

export interface Props {
  /** The component class name */
  className?: string

  /** The component inline style */
  style?: React.CSSProperties

  /** Whether the dialog is visible or not */
  visible?: boolean

  /** The share dialog's title */
  dialogTitle?: React.ReactNode

  /** The share title text */
  title?: string

  /** The share text */
  text?: string

  /** The share url */
  url?: string

  /** The current user data */
  user?: {
    name?: string
    username?: string
  }

  /** The share images will be display as the cover */
  images?: string | string[]

  /** Whether hidden the download button */
  disableDownload?: boolean

  /** Specify a function that will be called when dialog should close */
  onClose?: (event: React.MouseEvent) => void
}

/**
 * Share Dialog component
 */
function ShareDialog({
  className,
  style,
  visible = false,
  dialogTitle = "Share",
  title,
  text,
  url = document.location.href,
  user,
  images,
  disableDownload = !isSupportDownload,
  onClose,
}: Props): JSX.Element {
  title = title ?? `${user?.name ?? user?.username} sent you a pick!`
  text = text ?? url.replace(/^https?:\/\//i, "")

  const [showCopied, setShowCopied] = useState(false)

  const shareData = { title, text, url }
  const isUrlImage = _.isEmpty(images)
  const isSingleImage = !_.isEmpty(images) && _.isString(images)
  const isMultipleImages = !_.isEmpty(images) && _.isArray(images)
  const showDownload = !disableDownload && isSingleImage

  const handleCopyLink = () => !showCopied && copyLink(url) && setShowCopied(true)
  const handleDownload = () => _.isString(images) && downloadImage(images)
  const handleSendLink = () => share(shareData)

  // the maximum number of images to be shown is 9,
  // and less than 9 will be left blank
  const shownImages: (string | undefined)[] = useMemo(() => {
    return _.isArray(images) ? _.times(9, (n) => images[n]) : []
  }, [images])

  useEffect(() => {
    // whenever the copied message showing, hiding it after 3 seconds
    showCopied && setTimeout(() => setShowCopied(false), 3000)
  }, [showCopied])

  return (
    <Modal
      wrapClassName={cn(styles.wrapper, className)}
      style={style}
      visible={visible}
      title={dialogTitle}
      footer={null}
      zIndex={3000}
      closeIcon={<i className="iconfont icon-exit" />}
      onCancel={onClose}
    >
      <div className={styles.container}>
        <div className={styles.cover}>
          {isMultipleImages && (
            <div className={styles.coverHeader}>
              <div className={styles.shapes}>
                <i className={styles.shapesCircle} />
                <i className={styles.shapesBar} />
              </div>
            </div>
          )}

          <div className={cn(styles.coverImage, { [styles.multipleImages]: isMultipleImages })}>
            {/* URL image */}
            {isUrlImage && (
              <div className={styles.urlImage}>
                <span className="iconfont icon-link" /> URL
              </div>
            )}

            {/* single image */}
            {isSingleImage && <img src={images as string} alt="Cover Image" />}

            {/* multiple images */}
            {isMultipleImages && (
              <div className={styles.coverImageList}>
                {shownImages.map((src, index) => (
                  <div key={index} className={styles.coverImageItem}>
                    {src && <img src={src} alt="Cover Image" />}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className={styles.coverActions}>
            {showDownload && (
              <Button
                className={styles.coverDownloadBtn}
                type="default"
                icon={<span className="iconfont icon-download" />}
                onClick={handleDownload}
              />
            )}
          </div>

          <div className={styles.coverFooter}>
            <h3>{title}</h3>
            <p>{text}</p>
          </div>
        </div>

        <div className={styles.footer}>
          <div className={styles.actions}>
            <div className={styles.actionsItem}>
              <Button
                className={cn(styles.actionBtn, styles.copyBtn)}
                type="default"
                icon={<span className="iconfont icon-copy" />}
                onClick={handleCopyLink}
              />
              <span>Copy Link</span>
              <span className={cn(styles.copiedMessage, { [styles.showing]: showCopied })}>
                <span className="iconfont icon-right" />
                Copied!
              </span>
            </div>

            {showDownload && (
              <div className={styles.actionsItem}>
                <Button
                  className={cn(styles.actionBtn, styles.downloadBtn)}
                  type="default"
                  icon={<span className="iconfont icon-download" />}
                  onClick={handleDownload}
                />
                <span>Download Images</span>
              </div>
            )}

            {canShare(shareData) && (
              <div className={styles.actionsItem}>
                <Button
                  className={cn(styles.actionBtn, styles.sendBtn)}
                  type="default"
                  icon={<span className="iconfont icon-send" />}
                  onClick={handleSendLink}
                />
                <span>Send Link</span>
              </div>
            )}
          </div>

          <div className={styles.description}>
            Share with your friends or potential customers <br />
            and let them vote so you can better gauge the <br />
            success of your products!
          </div>
        </div>
      </div>
    </Modal>
  )
}

function copyLink(url: string): boolean {
  return Common.copyToClipboard(url)
}

function downloadImage(url?: string): void {
  // tips user manual save if run in mobile brower
  if (isMobile().any) {
    Common.toast("Please long press the picture to save!")
    return
  }

  url && saveAs(url, _.last(url.split("/")))
}

type ShareData = {
  title?: string
  text?: string
  url?: string
  files?: File[]
}

interface Navigator {
  share?: (data?: ShareData) => Promise<void>
  canShare?: (data?: ShareData) => boolean
}

async function share(data: ShareData) {
  if ((window?.navigator as Navigator)?.share) {
    return window.navigator.share(data)
  }
}

function canShare(data: ShareData): boolean {
  return !!(window?.navigator as Navigator)?.canShare?.(data)
}

function mapStateToProps(state: any) {
  return {
    user: state.app.currentUser.user,
  }
}

export default connect(mapStateToProps)(ShareDialog)
