import { Empty, Image, Modal } from "antd"
import cn from "classnames"
import _ from "lodash"
import React from "react"

import BaseButton from "@/components/Common/BaseButton"

import styles from "./index.module.css"

export interface Props {
  /** The component class name */
  className?: string

  /** The component inline style */
  style?: React.CSSProperties

  /** Whether the dialog is visible or not */
  visible?: boolean

  /** The dialog title text */
  title?: React.ReactNode

  /** The product items data */
  products?: any[]

  /** Specify a function that will be called when dialog should close */
  onClose?: (event: React.MouseEvent) => void
}

/**
 * Products Dialog component
 */
function ProductsDialog({ className, style, visible = false, title = "Products", products, onClose }: Props): JSX.Element {
  return (
    <Modal
      wrapClassName={cn(styles.wrapper, className)}
      style={style}
      visible={visible}
      title={title}
      footer={null}
      zIndex={3000}
      closeIcon={<i className="iconfont icon-exit" />}
      onCancel={onClose}
    >
      <div className={styles.container}>
        {_.isEmpty(products) && <Empty description="No Data" />}

        <div className={styles.productList}>
          {products?.map?.((product) => (
            <div key={product?.id} className={styles.productItem}>
              <Image className={styles.image} src={product?.images?.[0]} preview={false} />
              <div className={styles.footer}>
                {/* <div className={styles.meta}>
                  <div className={styles.metaItem}>{product?.display_price}</div>
                </div> */}
                <div className={styles.actions}>
                  <a href={product.shopify_product_url} target="_blank" rel="noreferrer">
                    <BaseButton color="black" shape="round">
                      View Product
                    </BaseButton>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default ProductsDialog
