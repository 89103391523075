/**
 * application configs
 */

console.log('process.env.REACT_APP_BASE_URL: ', process.env.REACT_APP_BASE_URL)
const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://api.pickkiwi.jumpo.club/'
// const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://api.pickkiwi.com/'

export default class AppCfg {
  static APP_ENV = BASE_URL?.startsWith('https://api.pickkiwi.jumpo.club') ? 'dev' : 'prod'
  static API_SERVER_URL = BASE_URL || 'https://api.pickkiwi.jumpo.club'
  static API_PREFIX = '/api/v1'
}
