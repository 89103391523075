import "./index.css"

import { Input, Radio, Select } from "antd"
import cn from "classnames"
import * as React from "react"
import { Link } from "react-router-dom"

import BaseButton from "@/components/Common/BaseButton"
import { requestSortOpts, requestStatusOpts } from "@/resources/options"

import { connect } from "react-redux"
import Styles from "./index.module.css"

const { Option } = Select

class SearchBar extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      search: "",
      sort_by: "-create_time",
      status: "ALL",
      searchExpanded: false,
    }
  }

  render() {
    const { status, search, searchExpanded } = this.state

    const { user } = this.props

    return (
      <div className={`${Styles.SearchBarMain} SearchBarMain`}>
        <div className={Styles.searchBarContent}>
          {/* search */}
          <div className={cn(Styles.search, { [Styles.searchExpanded]: searchExpanded })}>
            <span className={Styles.title}>
              <span className={`iconfont icon-search ${Styles.iconSeach}`} onClick={this.expandSearch}></span>
            </span>
            <Input placeholder="Search.." onChange={this.changeSearch} onKeyPress={this.handleEnterKey} value={search} />
            <span className="iconfont icon-exit" onClick={this.clearSearch}></span>
          </div>
          {/* sort by */}
          <div className={Styles.sortBy}>
            <span className={Styles.title}>Sort by</span>
            <Select
              onChange={this.changeSort.bind(this)}
              defaultValue="-create_time"
              style={{ width: 242 }}
              bordered={false}
              dropdownMatchSelectWidth={false}
            >
              {requestSortOpts.map((opt: any, index: any) => (
                <Option value={opt.value} key={index}>
                  {opt.label}
                </Option>
              ))}
            </Select>
          </div>

          {/*  Status */}
          <div className={Styles.sortBy}>
            <Radio.Group onChange={this.changeStatus.bind(this)} value={status} style={{ marginTop: 16 }}>
              {requestStatusOpts.map((opt: any, index: any) => (
                <Radio.Button value={opt.value} key={index}>
                  {opt.label}
                </Radio.Button>
              ))}
            </Radio.Group>
          </div>
          <div className={Styles.productSourced}>
            <Link to="/pick/NewRequest">
              <BaseButton color={"black"} shape={"round"}>
                Create request
              </BaseButton>
            </Link>
          </div>
        </div>
      </div>
    )
  }

  jugdeLink = () => {
    if (!this.props.user.shopify_collection_link) {
      console.log("链接为空")
    }
  }

  handleProductSourced = () => {
    this.setState(
      {
        status: "SUCCESS",
      },
      () => {
        this.commonFilterFunc()
      }
    )
  }

  changeSearch = (e: any) => {
    this.setState({
      search: e.target.value,
    })
  }

  expandSearch = () => {
    this.setState({ searchExpanded: true })
  }

  clearSearch = () => {
    this.setState({
      search: "",
      searchExpanded: false,
    })
  }

  handleEnterKey = (e: any) => {
    if (e.nativeEvent.keyCode === 13) {
      this.commonFilterFunc()
    }
  }

  changeSort = (value: any) => {
    this.setState(
      {
        sort_by: value,
      },
      () => {
        this.commonFilterFunc()
      }
    )
  }

  changeStatus = (e: any) => {
    this.setState(
      {
        status: e.target.value,
      },
      () => {
        this.commonFilterFunc()
      }
    )
  }

  commonFilterFunc = () => {
    const { search, sort_by, status } = this.state
    const { upsterRequestListArgs } = this.props
    upsterRequestListArgs({
      search: search || undefined,
      sort_by,
      status,
    })
  }
}

function mapStateToProps(state: any) {
  return {
    user: state.app.currentUser.user,
  }
}

export default connect(mapStateToProps)(SearchBar)
