import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import { Avatar, Button, Carousel, Divider, Empty, Image, Spin } from "antd"
import cn from "classnames"
import _, { isBoolean } from "lodash"
import React, { useEffect, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { Link, useParams } from "react-router-dom"

import { getShareList, vote } from "@/services/share"
import styles from "./index.module.css"

export interface Props {
  /** The component class name */
  className?: string

  /** The component inline style */
  style?: React.CSSProperties
}

/**
 * Share Picks page (user picks share)
 */
function SharePicks({ className, style }: Props): JSX.Element {
  const [data, setData] = useState<any>(null)
  const [error, setError] = useState<Error | null>(null)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const imageListRef = useRef<HTMLDivElement>(null)
  const { userId } = useParams()

  const isInitialLoad = data === null && error === null
  const pageSize = 35
  const maxPage = Math.ceil(total / pageSize)
  const hasMore = page < maxPage
  const userName = data?.user?.name || data?.user?.username || "-"

  const loadData = async () => {
    if (loading) return

    const currentPage = page + 1
    setLoading(true)
    try {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const tagIdsRaw = urlParams.get("tag_ids")
      let tagIds: number[]
      try {
        // @ts-ignore
        tagIds = tagIdsRaw?.split(",").map(x => parseInt(x)).filter(Number)
      } catch (e) {
        tagIds = []
      }

      const res = await getShareList({
        user_id: Number(userId),
        tag_ids: tagIds,
        page: currentPage,
        per_page: pageSize,
      })
      if (_.isEmpty(res)) throw new Error("Empty Data")

      if (isInitialLoad) {
        // initial load
        setData(res)
      } else {
        // load more
        const newData = _.cloneDeep(data)
        newData?.items?.push?.(...(res?.items ?? []))
        setData(newData)
      }

      setTotal(res?.total)
      setPage(currentPage)
      setLoading(false)
    } catch (err) {
      if (isInitialLoad) {
        setError(err as Error)
        setLoading(false)
      }
    }
  }

  // initial load data
  useEffect(() => {
    userId && loadData()
  }, [])

  // fix InfiniteScroll not load more when data items less than one screen height
  useEffect(() => {
    if (loading || _.isEmpty(data) || !hasMore || !imageListRef.current) return

    // imageListRef.current?.getBoundingClientRect().bottom <= window.innerHeight && loadData()
    const lastItem = imageListRef.current.lastElementChild
    const rect = lastItem?.getBoundingClientRect()

    // when image list last item in viewport range or the right side has more extra space to load more data
    rect && (rect.bottom <= window.innerHeight || rect.right <= window.innerWidth - rect.width) && loadData()
  }, [loading])

  return (
    <div className={cn(styles.container, className)} style={style}>
      {loading && isInitialLoad && <Spin className={styles.loading} tip="Loading..." size="large" />}

      {error && <Empty className={styles.empty} description={error?.message} />}

      {data && (
        <>
          <header className={styles.header}>
            <div className={styles.userInfo}>
              <Avatar src={data?.user.photo} icon={<span className="iconfont icon-accountline" />} />
              <h1>{userName}</h1>
            </div>
          </header>

          <div className={styles.description}>
            <h2>My Picks</h2>
            <h3>I'm curating new drops!</h3>
            <p>Collaborate with me by thumbing up and down</p>
          </div>

          <InfiniteScroll
            dataLength={data?.items?.length ?? 0}
            next={loadData}
            hasMore={hasMore}
            loader={<Spin className={styles.scrollLoading} tip="Loading..." />}
            endMessage={<Divider plain>No More Picks</Divider>}
          >
            <div ref={imageListRef} className={styles.imageList}>
              {data?.items?.map?.((item: any, index: number) => (
                <ImageItem key={item?.share_id} index={index} item={item} />
              ))}
            </div>
          </InfiniteScroll>
        </>
      )}
    </div>
  )
}

type VoteState = "unvote" | "like" | "unlike"

/**
 * ImageItem component
 */
function ImageItem({ item, index }: { item: any; index: number }): JSX.Element {
  const [itemState, setItemState] = useState(item)
  const [voteState, setVoteState] = useState<VoteState>("unvote")
  const { userId } = useParams()
  const isVoted = voteState !== "unvote"
  const imageSrc = item?.pick_type === "ITEM" ? item?.item?.cover_image : item?.images?.[0]

  const images = item?.pick_type === "ITEM" ? item?.item?.images : item?.images

  images?.length === 0 ? images?.push(imageSrc) : images

  const handleLike = () => {
    vote({ pick_id: item?.id, like: true })
    const newItem = JSON.parse(JSON.stringify(itemState))
    newItem.vote_like = true
    setItemState(newItem)
    // setVoteState('like')
  }

  const handleUnlike = () => {
    vote({ pick_id: item?.id, like: false })

    const newItem = JSON.parse(JSON.stringify(itemState))
    newItem.vote_unlike = true
    setItemState(newItem)
    // setVoteState('unlike')
  }
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const tagIdsRaw = urlParams.get("tag_ids")
  let tagIds: number[]
  try {
    // @ts-ignore
    tagIds = tagIdsRaw?.split(",").map(x => parseInt(x)).filter(Number)
  } catch (e) {
    tagIds = []
  }

  return (
    <div className={cn(styles.imageItem, { [styles.voted]: isVoted })}>
      <div className={styles.imageWrapper}>
        {/* <Link to={`/ShareDetail/${item?.share_id}`}> */}
        <Image.PreviewGroup>
          <Carousel dots={false} arrows={true} prevArrow={<LeftOutlined />} nextArrow={<RightOutlined />}>
            {images?.map((src: string) => (
              <a key={src}
                 href={`/ShareDetail/${itemState?.share_id}?user_id=${userId}&offset=${0}&limit=20&tag_ids=${tagIds.join(",")}`}
                 target="_blank" rel="noreferrer">
                <Image preview={false} className={styles.image} src={src} />
              </a>
            ))}
          </Carousel>
        </Image.PreviewGroup>

        {/* </Link> */}
      </div>

      <div className={styles.actions}>
        {!itemState.vote_like && !itemState.vote_unlike ? (
          <>
            <Button className={styles.likeBtn} type="default" block icon={<span className="iconfont icon-thumbup" />}
                    onClick={handleLike} />
            <Button className={styles.unlikeBtn} type="default" block
                    icon={<span className="iconfont icon-thumbdown" />} onClick={handleUnlike} />
          </>
        ) : (
          <div className={styles.voteMessage}>
            {itemState.vote_like && <span className="iconfont icon-thumbup" />}
            {itemState.vote_unlike && <span className="iconfont icon-thumbdown" />}
            Thanks for voting!
          </div>
        )}
      </div>
    </div>
  )
}

export default SharePicks
