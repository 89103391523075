import { Button, message } from "antd"
import React from "react"

import Styles from "./css/OrganizeBoard.module.css"

class OrganizeBoard extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = { a: "OrganizeBoard" }
  }

  handleOrgnizeBoard = () => {
    message.info("Under construction!")
  }

  render() {
    return (
      <div className={Styles.OrganizeBoardMain}>
        <Button
          type="primary"
          onClick={this.handleOrgnizeBoard}
          className={Styles.btnOrgnize}
          icon={<span className={`iconfont icon-apps ${Styles.icon}`} />}
        >
          Organize Board
        </Button>
      </div>
    )
  }
}

export default OrganizeBoard
