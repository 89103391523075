import React from "react"

import Styles from "./index.module.css"
import PhoneForm from "./PhoneForm"

function LoginForm() {
  return (
    <div className={Styles.LoginForm}>
      <PhoneForm />
    </div>
  )
}

export default LoginForm
