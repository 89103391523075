import { Spin } from "antd"
import cn from "classnames"
import _ from "lodash"
import React from "react"

import Container from "@/components/Common/Container"
import Footer from "@/components/Common/Footer"
import { getRequests } from "@/services/pick"

import Empty from "./components/Empty"
import ProductItem from "./components/ProductItem"
import Styles from "./index.module.css"

class SuccessProducts extends React.Component<any, any> {
  page = 1
  pageSize = 20

  constructor(props: any) {
    super(props)

    this.state = {
      loading: false,
      products: [],
    }
  }

  componentDidMount() {
    this.loadRequests()
  }

  async loadRequests(arg?: any) {
    this.setState({ loading: true })

    const res: any = await getRequests({
      page: this.page,
      per_page: this.pageSize,
      sort_by: "-create_time",
      status: "SUCCESS",
    })

    // console.log('loadRequests', res)
    const requests: any = res.items || []
    const products: any = []

    for (const request of requests) {
      const requestProducts = request.shopify_products || []
      requestProducts.forEach((product: any) => {
        products.push(product)
      })
    }

    this.setState({
      products: products,
      allNum: res.total,
      loading: false,
    })
  }

  render() {
    const { loading, products } = this.state
    const isEmptyPage = !loading && _.isEmpty(products)

    return (
      <Container className={cn({ [Styles.emptyPage]: isEmptyPage })}>
        {/* <SearchBar upsterRequestListArgs={this.loadRequests} showButton={false} /> */}

        <div className={Styles.productsView}>
          {products.map((p: any, index: any) => (
            <ProductItem key={index} product={p} onViewProduct={null} />
          ))}
        </div>

        {isEmptyPage && <Empty hasFilter={false} />}

        {loading ? (
          <Spin tip="Loading...">
            <div className={Styles.loading}></div>
          </Spin>
        ) : null}
        <Footer />
      </Container>
    )
  }
}

export default SuccessProducts
