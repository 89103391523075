import Header from "../../components/Common/Header"
import Styles from "./index.module.css"
import Html from "./Html"

function Index() {
  return (
    <>
      <Header className={Styles.loginHeader} showButtons={false} />
      <Html />
    </>
  )
}

export default Index
