import { Button, message, Popconfirm } from "antd"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import AutoLoadingButton from "../../../../components/Common/AutoLoadingButton"
import CommonStyle from "../../../../resources/styles/common.module.css"
import { deleteItem as curlDeleteItem } from "../../../../services/pick"
import Styles from "./css/ProductItem.module.css"

function ProductItem(props: any) {
  const [item, setItem] = useState(Object)
  const [deleteVisible, setDeleteVisible] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    setItem(props.item)
  }, [props.item])

  function goDetail() {
    const id_obj = item.request_id
      ? {
          requestId: item.request_id,
        }
      : {
          pickId: item.id,
        }
    const id = id_obj?.requestId || id_obj?.pickId
    const idType = id_obj?.requestId ? "request" : "pick"
    navigate(`/Pick/RequestDetail/${id}?type=${idType}`, {
      state: {
        ...id_obj,
        from: "pickList",
      },
    })
  }

  function handleRequest() {
    props.onRequest && props.onRequest(item)
  }

  function deleteItem() {
    const { removeItem } = props
    curlDeleteItem(item?.id)
      .then((res: any) => {
        if (res === "ok") {
          message.success("delete successful")
          removeItem(item)
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
      .then(() => {
        setDeleteVisible(false)
      })
  }

  return (
    <div className={Styles.productItem}>
      <div className={Styles.modelMain}>
        <div className={`${Styles.modelMainItem} ${CommonStyle.imgHoverZoom}`} onClick={goDetail}>
          <img src={item.pick_type === "ITEM" ? item?.item?.cover_image : item?.images?.[0]} alt="" />
        </div>
        <div className={Styles.btns}>
          {/* <Button className={Styles.move} shape="circle" size="large" icon={<span className="iconfont icon-move1" />}></Button> */}
          <Popconfirm
            title="Delete this pick?"
            visible={deleteVisible}
            onConfirm={deleteItem}
            okText="Yes"
            cancelText="No"
            onCancel={() => {
              setDeleteVisible(false)
            }}
          >
            <Button
              onClick={() => {
                setDeleteVisible(true)
              }}
              className={Styles.delete}
              shape="circle"
              size="large"
              icon={<span className="iconfont icon-trash" />}
            />
          </Popconfirm>
          {!item.request_id ? (
            // not request
            <>
              <AutoLoadingButton className={Styles.requset} color={"white"} shape="round" onClick={handleRequest}>
                Request Drops
              </AutoLoadingButton>
              <Button
                className={Styles.mobileRequset}
                type="dashed"
                shape="circle"
                icon={<span className="iconfont icon-plus" />}
                onClick={handleRequest}
              />
            </>
          ) : (
            // requested
            <>
              <AutoLoadingButton className={Styles.requseted} color={"black"} shape="round" onClick={goDetail}>
                View Request
              </AutoLoadingButton>
              <Button
                className={Styles.mobileRequseted}
                type="dashed"
                shape="circle"
                icon={<span className="iconfont icon-right" />}
                onClick={goDetail}
              />
            </>
          )}
        </div>

        {item.request_id && (
          <div className={Styles.requestedTips}>
            <AutoLoadingButton
              className={Styles.requestedTipsBtn}
              icon={<i className={`iconfont icon-right ${Styles.requestedTipsBtnIcon}`} />}
              color={"black"}
              shape="round"
              onClick={handleRequest}
            >
              Requested
            </AutoLoadingButton>
          </div>
        )}
      </div>

      <div className={Styles.productInfo}>
        <div className={Styles.likeMain}>
          <span className="iconfont icon-thumbupline" style={{ color: "#a6cf03" }}></span>
          <span className={Styles.like}>{item.like}</span>
          <span className="iconfont icon-thumbdownline" style={{ color: "#FF5050" }}></span>
          <span className={Styles.unlike}>{item.unlike}</span>
        </div>
      </div>
    </div>
  )
}

export default ProductItem
