import Styles from "./index.module.css"
import { Button } from "antd"
function Footer() {
  return (
    <div className={Styles.FooterMain}>
      <div className={Styles.desc}>
        <div className={Styles.desc_t1}>Discover products from anywhere.</div>

        <div className={Styles.desc_t2}>
          Curate your style, gather images that show off your vibe! Pick images, products, or links to your picks with our google chrome plugin, and
          request sourcing here.
        </div>
        <div className={Styles.downLoad}>
          <Button
            className={Styles.downLoadBtn}
            onClick={() => window.open("https://chrome.google.com/webstore/detail/a-kiwi-pick/cmgglgjgcgmhjcmlkbcfannfenmhfmdh")}
          >
            Download Plugin
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Footer
