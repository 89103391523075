/* eslint-disable react/no-string-refs, no-case-declarations */
import { Button } from "antd"
import _ from "lodash"
import queryString from "query-string"
import React from "react"

import { addRequest } from "@/services/pick"
import Common from "@/utils/common"

import Styles from "./index.module.css"
import RequestFormMain from "./RequestFormMain"
import UploadImages from "./UploadImages"
import Urls from "./Urls"

class RequestFormEntry extends React.Component<any, any> {
  private presetUrls: string

  constructor(props: any) {
    super(props)
    this.presetUrls = ""
    this.state = {
      tabs_search: ["?tab=images", "?tab=urls", "?tab=sync_store"],
      activeTabIndex: null,

      requestDone: false,
    }
  }

  render() {
    const { activeTabIndex, requestDone } = this.state
    const uploadImages = _.get(this.refs.UploadImages, ["state", "fileList"]) || []

    if (requestDone) {
      return (
        <div className={Styles.requestDone}>
          <div className={Styles.mark}>
            <span className={`${Styles.doneIcon} iconfont icon-right`}></span>
            {uploadImages && uploadImages.length > 0 ? (
              <img src={uploadImages[0]} />
            ) : (
              <span className={`iconfont ${Styles.resultImg}`}>&#xe676;</span>
            )}
          </div>

          <div className={Styles.basicText}>
            <h1>Sourcing Request Submitted!</h1>
            <p>We’ll get back to you within 48 hours. Check back soon!</p>
          </div>

          <div className={Styles.handleBtns}>
            <Button className={Styles.reqBtn} onClick={this.toRequests}>
              View My Requests
            </Button>
            <Button
              className={Styles.moreBtn}
              onClick={() => {
                this.props.backTab()
              }}
            >
              Request More
            </Button>
          </div>
        </div>
      )
    }

    const formTitle =
      activeTabIndex === 0 ? "Request with images" : activeTabIndex === 1 ? "Request with URLs" : "Request Drops existing items in your store"

    return (
      <div className={Styles.mainContent}>
        <h2 className={Styles.formTitle}>{formTitle}</h2>
        {activeTabIndex === 0 && <UploadImages ref="UploadImages" />}
        {activeTabIndex === 1 && <Urls ref="Urls" presetUrls={this.presetUrls} />}
        <RequestFormMain backTab={this.props.backTab} formSubmit={this.formSubmit} />
      </div>
    )
  }

  toRequests = () => {
    window.location.href = "/Pick/RequestList"
  }

  formSubmit = (reqBody: any) => {
    const { activeTabIndex } = this.state
    switch (activeTabIndex) {
      case 0: // images
        const UploadImagesCom: any = this.refs.UploadImages
        if (UploadImagesCom.state.fileList.length === 0) {
          UploadImagesCom.showErr()
          return
        }
        reqBody = {
          ...reqBody,
          custom_product: {
            ...reqBody.custom_product,
            images: UploadImagesCom.state.fileList,
          },
        }
        break
      case 1: // urls
        const UrlsCom: any = this.refs.Urls
        const submitUrls = UrlsCom.getSubmitUrls()
        if (!(submitUrls && submitUrls.length > 0)) {
          return Common.toast("Please enter request url", "warn")
        }

        reqBody = {
          ...reqBody,
          custom_product: {
            ...reqBody.custom_product,
            source_urls: submitUrls.map((u: any) => u.link),
          },
        }
        break
    }
    this.curlApiForSubmit(reqBody)
  }

  curlApiForSubmit = (finalReqBody: any) => {
    console.log(finalReqBody)
    addRequest(finalReqBody).then((res) => {
      if (res) {
        this.setState({
          requestDone: true,
        })
      }
    })
  }

  getImage = (imgs: any) => {
    console.log(imgs)
  }

  componentDidMount() {
    const { backTab } = this.props
    const { tabs_search } = this.state

    const parsed: any = queryString.parse(window.location.search)
    const tagName = "?tab=" + parsed.tab

    if (!tabs_search.includes(tagName)) {
      backTab()
    } else {
      this.presetUrls = parsed.from_url
      this.setState({
        activeTabIndex: tabs_search.indexOf(tagName),
      })
    }
  }
}

export default RequestFormEntry
