import { useEffect, useState } from "react"

/**
 * Checks if the current document matches a given media query.
 * @param mediaQuery {string} media query string
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia#parameters
 * @returns {boolean} Returns the match state of the given media query.
 */
export default function useMediaQuery(mediaQuery: string): boolean {
  const [isMatch, setIsMatch] = useState(!!window.matchMedia(mediaQuery).matches)

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery)
    const handler = () => setIsMatch(!!mediaQueryList.matches)

    try {
      mediaQueryList.addEventListener("change", handler)
    } catch (e) {
      // Before Safari 14 isn't supporting mediaQueryList.addEventListener
      mediaQueryList.addListener(handler)
    }

    handler()
    return () => {
      try {
        mediaQueryList.removeEventListener("change", handler)
      } catch (e) {
        // Before Safari 14 isn't supporting mediaQueryList.removeEventListener
        mediaQueryList.removeListener(handler)
      }
    }
  }, [mediaQuery])

  return isMatch
}
