import React, { useState } from "react"

import { convertToUSD } from "@/utils/currency"

import CommonStyle from "../../resources/styles/common.module.css"
import { getRequest } from "../../services/pick"
import TitleBar from "../Common/TitleBar"
import DropsForYou from "./components/DropsForYou"
import Styles from "./components/index.module.css"
import MyNotes from "./components/MyNotes"
import MyTagsList from "./components/MyTagsList"
import ProductImages from "./components/ProductImages"
import RequestProgressBar from "./components/ProgressBar"
import RequestFormInfo from "./components/RequestFormInfo"
import RequestStatusBar from "./components/RequestStatusBar"
import SimilarProducts from "./components/SimilarProducts"
// import SourcingTeamNote from './components/SourcingTeamNote'

/**
 * Request Detail 和 Pick Detail 的区别在于, Request Detail 多 Similar Products 和
 * Request Receipt (User submitted details)，其他两者是一致的
 * @constructor
 */
const ProductDetail = (props: any) => {
  const { product, formatProduct } = props

  const [productState, setProductState] = useState(product)
  const [productImageIndex, setProductImageIndex] = useState(0)

  const updateRequestStatus = (requestId: any) => {
    getRequest(requestId).then(async (res) => {
      if (res) {
        console.log(res)
        const newProduct = await formatProduct(res, { hasRequset: true })
        setProductState(newProduct)
      }
    })
  }

  const handleProductImageChange = (current: number) => {
    setProductImageIndex(current)
  }

  return (
    <div className={Styles.container}>
      {/* title bar for mobile */}
      <TitleBar title={productState.request_id ? "Your Request" : "Your Pick"} />
      <RequestProgressBar product={productState} />
      <RequestStatusBar product={productState} updateRequestStatus={updateRequestStatus} productImageIndex={productImageIndex} />

      {/* drops for you  */}
      <DropsForYou product={productState} />
      {/* SimilarProducts 只有在requset faild 时出现（推荐相似的商品） */}
      {productState.similar_products && productState.similar_products.length > 0 && (
        <SimilarProducts similarProducts={productState.similar_products} teamNotes={productState.team_notes} />
      )}

      {/* requested SourcingTeamNote  */}
      {/* {productState.request_id && (productState.status === 'SUCCESS' || productState.status === 'FAILED') && (
        <div className={`${CommonStyle.row} ${CommonStyle.card}`}>
          <SourcingTeamNote team_notes={productState.team_notes} />
        </div>
      )} */}

      {/* 当前商品存在requset时，才有对应的requsetForm */}
      {productState.request_id && <RequestFormInfo requestFormInfo={productState} />}

      {/* 商品基础信息 */}
      <div className={`${CommonStyle.column} ${Styles.productInfoView}`}>
        {/* 判断source_urls和images_request  */}
        {/* 如果source_urls存在 说明需要显示urls request的detail
            如果images_request存在 说明需要显示 images request的detail
        */}
        <div className={`${CommonStyle.row} ${CommonStyle.card}`}>
          {/* pick | images request */}
          {/* 说明：pick request 里面包含了images, images request 也有images 公用一个组件 */}
          <ProductImages productImages={productState.images} onChange={handleProductImageChange} />

          {/* pick  */}
          {productState.pick_type === "ITEM" && (
            <div>
              <div>
                Orig. Price: ${convertToUSD(productState.price, productState.currency).amount} on{" "}
                <a target="_blank" href={productState.source_url} rel="noreferrer">
                  {product.product_vendor?.name}
                </a>
              </div>
              {/* <div className={CommonStyle.tb}>
                Est. Price: {productState.est_price ? '$' + convertToUSD(productState.est_price, productState.currency).amount : '-'}
              </div> */}
            </div>
          )}

          {/* urls request */}
          {productState.source_urls &&
            productState.source_urls.map((url: any, index: number) => (
              <div key={index} className={`${CommonStyle.column} ${Styles.urlsDom}`}>
                <span className="iconfont icon-link" />
                <a href={url} title={url} target={"_blank"} rel="noreferrer">
                  {url}
                </a>
              </div>
            ))}
        </div>

        {/* pick  */}
        {productState.pick_id && (
          <div className={`${CommonStyle.row} ${CommonStyle.card} ${Styles.tagView}`}>
            <MyTagsList tags={productState.tags || []} />
            <MyNotes notes={productState.notes} />
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductDetail
