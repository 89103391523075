// 为了修改一些组件的UI 正常修改全局的antd.css即可 但是 有的组件的样式不是公共 所以单独引入一个css 进行修改（这个css 只在局部生效）
import "./index.css"

import { Checkbox, Form, Input, Radio, Select } from "antd"
import React from "react"

import BaseButton from "../../../../components/Common/BaseButton"
import { currencyOpts, destinationCountryOpts, orderTypeOpts, productCategoryOpts } from "../../../../resources/options/index"
import CommonStyles from "../../../../resources/styles/common.module.css"
import Styles from "./index.module.css"

const { Option } = Select

const RequestFormMain = (props: any) => {
  const { formRef, backTab } = props

  const onFinish = (values: any) => {
    console.log("Success:", values)
    // const { backTab } = props

    // "pick_id": 0,
    // "destination_country": "string",
    // "plan_sell": 0,
    // "plan_sell_currency": "string",
    // "quantity": 0,
    // "product_category": "string",
    // "order_type": "string",
    // "custom_product": {
    //   "title": "string",
    //   "images": [
    //     "string" // imgs
    //   ],
    //   "source_urls": [
    //     "string"  // urls
    //   ]
    // },

    const reqBody = {
      ...values,
      ...values.plan,
      custom_product: {
        title: values.title,
      },
      accept_similar: values.accept_similar || false,
    }
    delete reqBody.plan
    delete reqBody.title
    console.log(reqBody)

    props.formSubmit(reqBody)
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo)
  }

  return (
    <div className={`requestFormMain ${Styles.requestFormMain}`}>
      <div className={Styles.form}>
        <Form ref={formRef} name="basic" layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item
            label="Request Title (find this request later)"
            name="title"
            rules={[{ required: false, message: "Please input what you want!" }]}
          >
            <Input placeholder={"Large Candleholder with 5 spikes"} />
          </Form.Item>

          <div className={`${CommonStyles.column} ${Styles.orderTypeRow}`}>
            <Form.Item label="Order Type" initialValue={"Wholesale"} name="order_type" style={{ width: "50%" }}>
              <Radio.Group size="large">
                {orderTypeOpts.map((opt: any, index: any) => (
                  <Radio.Button key={index} value={opt.value}>
                    {opt.label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Estimated Quantity" name="quantity" initialValue={1} style={{ width: "50%" }}>
              <Input type="number" min={1} />
            </Form.Item>
          </div>

          <Form.Item label="Plan to sell at (per unit)">
            <Input.Group>
              <Form.Item name={["plan", "plan_sell"]} noStyle rules={[{ required: false, message: "desiredCost is required" }]}>
                <Input className={Styles.costInput} placeholder="39.00" type="number" min={0} />
              </Form.Item>

              <Form.Item
                name={["plan", "plan_sell_currency"]}
                noStyle
                initialValue={"USD"}
                rules={[{ required: false, message: "currency is required" }]}
              >
                <Select style={{ width: 100, marginLeft: 10 }}>
                  {currencyOpts.map((opt: any, index: any) => (
                    <Option key={index} value={opt.value}>
                      {opt.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Input.Group>
          </Form.Item>

          <Form.Item
            label="Destination Country"
            name="destination_country"
            rules={[{ required: false, message: "Please select Destination Country" }]}
            initialValue={"US"}
          >
            <Select>
              {destinationCountryOpts.map((opt: any, index: any) => (
                <Option key={index} value={opt.value}>
                  {opt.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Product Category" name="product_category" initialValue={"Apparel"}>
            <Select defaultValue={"Apparel"}>
              <Option value={""}>Select</Option>
              {productCategoryOpts.map((opt: any, index: any) => (
                <Option key={index} value={opt.value}>
                  {opt.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="accept_similar" valuePropName="checked" initialValue={true} className={Styles.checkBoxRow}>
            <Checkbox>Accept similar products</Checkbox>
          </Form.Item>

          <Form.Item wrapperCol={{ md: { offset: 8, span: 16 } }} style={{ margin: 0, paddingBottom: 25 }}>
            <BaseButton
              className={Styles.cancelBtn}
              htmlType="button"
              color={"white"}
              shape={"round"}
              onClick={() => {
                console.log("?")
                backTab()
              }}
            >
              Cancel
            </BaseButton>
            <BaseButton type="primary" color={"black"} shape={"round"} className={Styles.submitBtn} htmlType="submit">
              Submit Request
            </BaseButton>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default RequestFormMain
