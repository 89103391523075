import "react-phone-input-2/lib/style.css"

import { Input, message } from "antd"
import React from "react"
import PhoneInput from "react-phone-input-2"

import AutoLoadingButton from "../../../components/Common/AutoLoadingButton"
import { sendValidation } from "../../../services/pick"
import Styles from "./index.module.css"

let timer: any = null
class Logins extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      ...props,

      editPhone: false,
      timeOut: 60,
      isDisabled: false,
      loadingVerificationCode: false,
      verificationCode: null,
    }
  }

  phoneChange = (phone: any) => {
    if (phone.indexOf("+86") > -1 || phone.indexOf("+852") > -1 || phone.indexOf("+886") > -1 || phone.indexOf("+853") > -1) {
      message.info("Service is not available in the selected region")
      return
    }

    this.setFormVal("phone", phone)
  }

  editPhoneHandle = () => {
    this.setState({
      editPhone: !this.state.editPhone,
    })
  }

  setFormVal = (key: any, value: any) => {
    const newUserInfo = Object.assign({}, this.state.userInfo, {
      [key]: value,
    })
    this.setState({
      userInfo: newUserInfo,
    })
  }

  _sendVerification = () => {
    const { userInfo } = this.state

    this.setState(
      {
        timeOut: 60,
        loadingVerificationCode: true,
      },
      () => {
        this.setVerificationCodeTimeOut()
      }
    )

    const obj = {
      phone: userInfo.phone,
      change: true,
    }

    sendValidation(obj).then((res) => {
      console.log(res)
      if (res) {
        this.setState({
          loadingVerificationCode: false,
        })
      } else {
        clearInterval(timer)
        this.setState({
          loadingVerificationCode: false,
          timeOut: 5,
          isDisabled: false,
        })
      }
    })
  }

  setVerificationCodeTimeOut = () => {
    this.setState({
      isDisabled: true,
    })
    let timeOut = this.state.timeOut - 1
    timer = setInterval(() => {
      if (this.state.timeOut === 0) {
        this.setState({
          isDisabled: false,
          loadingVerificationCode: false,
        })
        clearInterval(timer)
      }
      this.setState({
        timeOut: timeOut--,
      })
    }, 1000)
  }

  render() {
    const { editPhone, userInfo, timeOut, isDisabled, loadingVerificationCode } = this.state

    return (
      <div className={Styles.mainLogins}>
        <h3 className={Styles.title}>Logins</h3>

        <div className={Styles.loginsForm}>
          <div className={Styles.loginsFormItem}>
            <label className={Styles.loginsFormItemLabel}>Phone</label>

            {editPhone ? (
              <div>
                <div className={Styles.inputView}>
                  <PhoneInput
                    value={userInfo.phone}
                    inputProps={{
                      className: `ant-input ${Styles.loginsBtn}`,
                      onKeyPress: (e: any) => {
                        if (e.nativeEvent.keyCode === 13) {
                          this.editPhoneHandle()
                        }
                      },
                    }}
                    country={"us"}
                    enableLongNumbers={true}
                    preferredCountries={["us", "gb", "au", "ca"]}
                    excludeCountries={["cn", "hk", "tw", "mo"]}
                    countryCodeEditable={false}
                    onChange={this.phoneChange}
                  />
                  <AutoLoadingButton
                    loading={loadingVerificationCode}
                    disabled={isDisabled}
                    shape={"round"}
                    color={"balck"}
                    className={Styles.verificationBtn}
                    onClick={this._sendVerification}
                  >
                    {loadingVerificationCode ? timeOut : "Send Code"}
                  </AutoLoadingButton>
                </div>

                <div>
                  <Input
                    defaultValue={userInfo.verificationCode}
                    onChange={(e: any) => {
                      this.setFormVal("verificationCode", e.target.value)
                    }}
                    type="number"
                    className={`${Styles.loginsBtn} ${Styles.verificatCode}`}
                    placeholder="Your verification code..."
                  />
                </div>
              </div>
            ) : (
              <p className={Styles.loginFormItemVal}>{this.props.userInfo.phone || "-"}</p>
            )}

            <span className={Styles.editText} onClick={this.editPhoneHandle}>
              {editPhone ? "Cancel Change" : "Change Phone"}
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default Logins
