export const currencyObj: any = {
  USD: "$",
  CNY: "￥",
  EUR: "€",
  HKD: "HK$",
  GBP: "£",
}

export const currencyOpts: any = [
  {
    label: "$USD",
    value: "USD",
  },
  {
    label: "￥CNY",
    value: "CNY",
  },
  {
    label: "€EUR",
    value: "EUR",
  },
  {
    label: "HK$",
    value: "HKD",
  },
  {
    label: "£GBP",
    value: "GBP",
  },
]

export const orderTypeOpts: any = [
  {
    label: "Wholesale",
    value: "Wholesale",
  },
  {
    label: "Dropshipping",
    value: "Dropshipping",
  },
]

export const destinationCountryObj: any = {
  US: "United States",
  GD: "The United Kingdom",
  AUS: "Australia",
  CAN: "Canada",
  Other: "Other",
}

export const destinationCountryOpts: any = [
  {
    label: "United States",
    value: "US",
  },
  {
    label: "The United Kingdom",
    value: "GD",
  },
  {
    label: "Australia",
    value: "AUS",
  },
  {
    label: "Canada",
    value: "CAN",
  },
  {
    label: "Other",
    value: "Other",
  },
]

export const productCategoryOpts = [
  {
    label: "Apparel",
    value: "Apparel",
  },
  {
    label: "Jewelry",
    value: "Jewelry",
  },
  {
    label: "Home Decor",
    value: "Home Decor",
  },
  {
    label: "Pets",
    value: "Pets",
  },
]

export const requestStatusObj: any = {
  PROCESSING: "Processing",
  SUCCESS: "Success",
  FAILED: "Feedback",
}

export const requestStatusOpts = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "Processing",
    value: "PROCESSING",
  },
  {
    label: "Success",
    value: "SUCCESS",
  },
  {
    label: "Feedback",
    value: "FAILED",
  },
]

export const requestSortOpts = [
  {
    value: "-create_time",
    label: "Requested (Newest First)",
  },
  {
    value: "create_time",
    label: "Requested (Oldest First)",
  },
  {
    value: "-update_time",
    label: "Updated (Newest First)",
  },
  {
    value: "update_time",
    label: "Updated (Oldest First)",
  },
]
