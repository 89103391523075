import { Footer as AntFooter } from "antd/lib/layout/layout"
import React from "react"

import Styles from "./index.module.css"

const Footer = (props: any) => {
  return (
    <AntFooter className={`${Styles.footer} ${props.fixed ? Styles.fixed : ""}`}>
      <span>&copy; 2020 KiwiDrop</span>
    </AntFooter>
  )
}

export default Footer
