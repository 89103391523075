import CommonStyle from "@/resources/styles/common.module.css"

import Styles from "./index.module.css"

const ProductItem = (props: any) => {
  const { product } = props
  let title: string = product.title
  if (title.length > 50) {
    title = title.substr(0, 50) + "..."
  }

  return (
    <a className={`${CommonStyle.column} ${Styles.itemView}`} href={product.shopify_product_url} target="_blank" rel="noreferrer">
      <div className={Styles.imageWrapper}>
        <img className={Styles.productImage} src={product.images[0]} />
      </div>

      <div className={`${CommonStyle.column} ${Styles.itemContent}`}>
        <div>
          <div className={Styles.subTitle} dangerouslySetInnerHTML={{ __html: title || "-" }}></div>
          <div className={Styles.price}>{product.display_price}</div>
        </div>
      </div>
    </a>
  )
}

export default ProductItem
