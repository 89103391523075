import Styles from "./index.module.css"

const RenderHtml = () => {
  return (
    <main className={Styles.content} id="MainContent">
      <div className={Styles.policy__container}>
        <div className={Styles.policy__title}>
          <h1>Privacy policy</h1>
        </div>

        <div className={Styles.policy__body}>
          <div className={Styles.rte}>
            <p>
              <span style={{ fontWeight: 400 }}>
                This Privacy Policy describes how pickkiwi.com (the “Site” or “we”) collects, uses, and discloses your Personal Information when you
                visit or make a purchase from the Site.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <b>COLLECTING PERSONAL INFORMATION</b>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                When you visit the Site, we collect certain information about your device, your interaction with the Site, and information necessary
                to process your purchases. We may also collect additional information if you contact us for customer support. In this Privacy Policy,
                we refer to any information that can uniquely identify an individual (including the information below) as “Personal Information”. See
                the list below for more information about what Personal Information we collect and why.
              </span>
            </p>
            <p>
              <span style={{ textDecoration: "underline" }}>
                <span style={{ fontWeight: 400 }}>Device Information</span>
              </span>
            </p>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <b>Examples of Personal Information collected:</b>
                <span style={{ fontWeight: 400 }}>
                  version of web browser, IP address, time zone, cookie information, what sites or products you view, search terms, and how you
                  interact with the Site.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <b>Purpose of collection:</b>
                <span style={{ fontWeight: 400 }}>
                  to load the Site accurately for you, and to perform analytics on Site usage to optimize our Site.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <b>Source of collection:</b>
                <span style={{ fontWeight: 400 }}>
                  Collected automatically when you access our Site using cookies, log files, web beacons, tags, or pixels
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <b>Disclosure for a business purpose:</b>
                <span style={{ fontWeight: 400 }}>shared with our processor Paypal, Sezzle, Shop Pay, Amazon Web Services</span>
              </li>
            </ul>
            <p>
              <span style={{ textDecoration: "underline" }}>
                <span style={{ fontWeight: 400 }}>Order information</span>
              </span>
            </p>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <b>Examples of Personal Information collected:</b>
                <span style={{ fontWeight: 400 }}>
                  name, billing address, shipping address, payment information (including credit card numbers),&nbsp; email address, and phone number.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <b>Purpose of collection:</b>
                <span style={{ fontWeight: 400 }}>
                  to provide products or services to you to fulfill our contract, to process your payment information, arrange for shipping, and
                  provide you with invoices and/or order confirmations, communicate with you, screen our orders for potential risk or fraud, and when
                  in line with the preferences you have shared with us, provide you with information or advertising relating to our products or
                  services.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <b>Source of collection:</b>
                <span style={{ fontWeight: 400 }}> collected from you.</span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <b>Disclosure for a business purpose:</b>
                <span style={{ fontWeight: 400 }}>shared with our processor Shopify</span>
              </li>
            </ul>
            <p>
              <span style={{ textDecoration: "underline" }}>
                <span style={{ fontWeight: 400 }}>Minors</span>
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                The Site is not intended for individuals under the age of 13 . We do not intentionally collect Personal Information from children
                under the age of 13. If you are under 13, do not use or provide any information on the Site or through any of its features, register
                on the Website, make any purchases through the Website, use any of the interactive or public comment features of this Website, or
                provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or
                user name you may use. If we learn we have collected or received personal information from a child under 13 without verification of
                parental consent, we will delete that information. If you are the parent or guardian and believe your child has provided us with
                Personal Information, please contact us by email at{" "}
              </span>
              <a href="mailto:support@getkiwidrop.com">
                <span style={{ fontWeight: 400 }}>support@trykiwidrop.com</span>
              </a>
              <span style={{ fontWeight: 400 }}>or at the address listed below to request deletion.</span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <b>SHARING PERSONAL INFORMATION</b>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                We may disclose aggregated information about our users, and information that does not identify any individual, without restriction.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                We may disclose personal information that we collect or you provide as described in this privacy policy:
              </span>
            </p>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>To our subsidiaries and affiliates.</span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  To contractors, service providers, and other third parties we use to support our business. For example:
                </span>
              </li>
              <ul>
                <li style={{ fontWeight: 400 }}>
                  <span style={{ fontWeight: 400 }}>
                    We use Shopify to power our online store. You can read more about how Shopify uses your Personal Information here:
                  </span>
                  <a href="https://www.shopify.com/legal/privacy">
                    <span style={{ fontWeight: 400 }}>https://www.shopify.com/legal/privacy</span>
                  </a>
                  <span style={{ fontWeight: 400 }}>.</span>
                </li>
              </ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or
                  transfer of some or all of KiwiDrop’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar
                  proceeding, in which personal information held by KiwiDrop about our Site users is among the assets transferred.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>To fulfill the purpose for which you provide it.</span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>For any other purpose disclosed by us when you provide the information.</span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>With your consent.</span>
              </li>
            </ul>
            <p>
              <span style={{ fontWeight: 400 }}>We may also disclose your personal information:</span>
            </p>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  To comply with any court order, law, or legal process, including to respond to any government or regulatory request.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>To enforce or apply our terms of use </span>
                <span style={{ fontWeight: 400 }}>&nbsp;</span>
                <span style={{ fontWeight: 400 }}>and other agreements, including for billing and collection purposes.</span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of KiwiDrop, our customers, or
                  others.
                </span>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <p>
              <b>BEHAVIORAL ADVERTISING</b>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we
                believe may be of interest to you. For example:
              </span>
            </p>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  We use Google Analytics to help us understand how our customers use the Site. You can read more about how Google uses your Personal
                  Information here:
                </span>
                <a href="https://policies.google.com/privacy?hl=en">
                  <span style={{ fontWeight: 400 }}>https://policies.google.com/privacy?hl=en</span>
                </a>
                <span style={{ fontWeight: 400 }}>.You can also opt-out of Google Analytics here:</span>
                <a href="https://tools.google.com/dlpage/gaoptout">
                  <span style={{ fontWeight: 400 }}>https://tools.google.com/dlpage/gaoptout</span>
                </a>
                <span style={{ fontWeight: 400 }}>.</span>
              </li>
            </ul>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  We share information about your use of the Site, your purchases, and your interaction with our ads on other websites with our
                  advertising partners. We collect and share some of this information directly with our advertising partners, and in some cases
                  through the use of cookies or other similar technologies (which you may consent to, depending on your location).
                </span>
              </li>
            </ul>
            <p>
              <span style={{ fontWeight: 400 }}>
                For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page
                at
              </span>
              <a href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work">
                <span style={{ fontWeight: 400 }}>http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work</span>
              </a>
              <span style={{ fontWeight: 400 }}>.</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>You can opt out of targeted advertising by:</span>
            </p>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <i>
                  <span style={{ fontWeight: 400 }}>FACEBOOK -</span>
                </i>
                <a href="https://www.facebook.com/settings/?tab=ads">
                  <span style={{ fontWeight: 400 }}>https://www.facebook.com/settings/?tab=ads</span>
                </a>
              </li>
            </ul>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <i>
                  <span style={{ fontWeight: 400 }}>GOOGLE -</span>
                </i>
                <a href="https://www.google.com/settings/ads/anonymous">
                  <span style={{ fontWeight: 400 }}>https://www.google.com/settings/ads/anonymous</span>
                </a>
              </li>
            </ul>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <i>
                  <span style={{ fontWeight: 400 }}>BING -</span>
                </i>
                <a href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads">
                  <span style={{ fontWeight: 400 }}>https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads</span>
                </a>
              </li>
            </ul>
            <p>
              <span style={{ fontWeight: 400 }}>
                Additionally, you can opt-out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at:
              </span>
              <a href="http://optout.aboutads.info/">
                <span style={{ fontWeight: 400 }}>http://optout.aboutads.info/</span>
              </a>
              <span style={{ fontWeight: 400 }}>.</span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <b>USING PERSONAL INFORMATION</b>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                We use that we collect about you or that you provide to us, including your personal Information:
              </span>
            </p>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>To present our Site and its contents to you.</span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  To provide you with information, products, or services that you request from us, which includes: offering products for sale,
                  processing payments, shipping and fulfillment of your order, and keeping you up to date on new products, services, and offers.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>To fulfill any other purpose for which you provide it.</span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for
                  billing and collection.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  To notify you about changes to our Site or any products or services we offer or provide though it.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>In any other way we may describe when you provide the information.</span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>For any other purpose with your consent.</span>
              </li>
            </ul>
            <p>
              <span style={{ fontWeight: 400 }}>
                We may also use your information to contact you about goods and services that may be of interest to you. If you do not want us to use
                your information in this way, please adjust your user preferences in your account profile.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <b>LAWFUL BASIS</b>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Pursuant to the General Data Protection Regulation (“GDPR”), if you are a resident of the European Economic Area (“EEA”), we process
                your personal information under the following lawful bases:
              </span>
            </p>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>Your consent;</span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>The performance of the contract between you and the Site;</span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>Compliance with our legal obligations;</span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>To protect your vital interests;</span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>To perform a task carried out in the public interest;</span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>For our legitimate interests, which do not override your fundamental rights and freedoms.</span>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              <b>RETENTION</b>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                When you place an order through the Site, we will retain your Personal Information for our records unless and until you ask us to
                erase this information. For more information on your right of erasure, please see the ‘Your rights’ section below.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <b>AUTOMATIC DECISION-MAKING</b>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                If you are a resident of the EEA, you have the right to object to processing based solely on automated decision-making (which includes
                profiling), when that decision-making has a legal effect on you or otherwise significantly affects you.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                We do not engage in fully automated decision-making that has a legal or otherwise significant effect using customer data.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Our processor Shopify uses limited automated decision-making to prevent fraud that does not have a legal or otherwise significant
                effect on you.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>Services that include elements of automated decision-making include:</span>
            </p>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Temporary denylist of IP addresses associated with repeated failed transactions. This denylist persists for a small number of hours.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Temporary denylist of credit cards associated with denylisted IP addresses. This denylist persists for a small number of days.
                </span>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <p>
              <b>DO NOT SELL</b>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                We do not sell your Personal Information in the conventional sense (i.e., for money). However, like many companies, we use services
                that collect information from you (to deliver advertisements to you for example) and this may classNameify this use as a “sale” of
                your Personal Information, You can request to opt-out out of this “sale” here: Do Not Sell My Personal Information. If you direct us
                not to sell your Personal Information, we will stop sharing your personal information if required by applicable law.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <b>YOUR RIGHTS</b>
            </p>
            <p>
              <span style={{ textDecoration: "underline" }}>
                <span style={{ fontWeight: 400 }}>GDPR</span>
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                If you are a resident of the EEA, you have the right to access the Personal Information we hold about you, to port it to a new
                service, and to ask that your Personal Information be corrected, updated, or erased. If you would like to exercise these rights,
                please contact us by email at{" "}
              </span>
              <a href="mailto:support@getkiwidrop.com">
                <span style={{ fontWeight: 400 }}>support@trykiwidrop.com</span>
              </a>
              <span style={{ fontWeight: 400 }}>or at the address listed below</span>
              <i>
                <span style={{ fontWeight: 400 }}>.</span>
              </i>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Your Personal Information will be initially processed in Ireland and then will be transferred outside of Europe for storage and
                further processing, including to Canada and the United States. For more information on how data transfers comply with the GDPR, see
                Shopify’s GDPR Whitepaper:
              </span>
              <a href="https://help.shopify.com/en/manual/your-account/privacy/GDPR">
                <span style={{ fontWeight: 400 }}>https://help.shopify.com/en/manual/your-account/privacy/GDPR</span>
              </a>
              <span style={{ fontWeight: 400 }}>.</span>
              <br />
              <br />
            </p>
            <p>
              <b>CCPA</b>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                [If you are a California resident, California law may provide you with additional rights regarding our use of your personal
                information. To learn more about your California privacy rights, visit [
              </span>
              <span style={{ fontWeight: 400 }}>HYPERLINK TO CCPA PRIVACY NOTICE FOR CALIFORNIA RESIDENTS</span>
              <span style={{ fontWeight: 400 }}>].</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                If you are a resident of California, you have the right to access the Personal Information we hold about you (also known as the ‘Right
                to Know’), to port it to a new service, and to ask that your Personal Information be corrected, updated, or erased. If you would like
                to exercise these rights, please contact us through the contact information below.&nbsp;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                If you would like to designate an authorized agent to submit these requests on your behalf, please contact us at the address below.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <b>COOKIES</b>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                A cookie is a small amount of information that’s downloaded to your computer or device when you visit our Site. We use a number of
                different cookies, including functional, performance, advertising, and social media or content cookies. Cookies make your browsing
                experience better by allowing the website to remember your actions and preferences (such as login and region selection). This means
                you don’t have to re-enter this information each time you return to the site or browse from one page to another. Cookies also provide
                information on how people use the website, for instance whether it’s their first time visiting or if they are a frequent visitor.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                The length of time that a cookie remains on your computer or mobile device depends on whether it is a “persistent” or “session”
                cookie. Session cookies last until you stop browsing and persistent cookies last until they expire or are deleted. Most of the cookies
                we use are persistent and will expire between 30 minutes and two years from the date they are downloaded to your device.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                You can control and manage cookies in various ways. Please keep in mind that removing or blocking cookies can negatively impact your
                user experience and parts of our website may no longer be fully accessible.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Most browsers automatically accept cookies, but you can choose whether or not to accept cookies through your browser controls, often
                found in your browser’s “Tools” or “Preferences” menu. For more information on how to modify your browser settings or how to block,
                manage or filter cookies can be found in your browser’s help file or through such sites as
              </span>
              <a href="https://pickkiwi.com/policies/www.allaboutcookies.org">
                <span style={{ fontWeight: 400 }}>www.allaboutcookies.org</span>
              </a>
              <span style={{ fontWeight: 400 }}>.</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Additionally, please note that blocking cookies may not completely prevent how we share information with third parties such as our
                advertising partners. To exercise your rights or opt-out of certain uses of your information by these parties, please follow the
                instructions in the “Behavioural Advertising” section above.
              </span>
            </p>
            <p>
              <br />
            </p>
            <p>
              <b>DO NOT TRACK</b>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                Please note that because there is no consistent industry understanding of how to respond to “Do Not Track” signals, we do not alter
                our data collection and usage practices when we detect such a signal from your browser.{" "}
              </span>
              <span style={{ fontWeight: 400 }}>To find out more about “Do Not Track,” please visit&nbsp;</span>
              <a href="http://www.allaboutdnt.com/">
                <span style={{ fontWeight: 400 }}>http://www.allaboutdnt.com</span>
              </a>
              <span style={{ fontWeight: 400 }}>.</span>
            </p>
            <p>&nbsp;</p>
            <p>
              <b>CHANGES</b>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other
                operational, legal, or regulatory reasons.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <b>CONTACT</b>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us
                by email at
                <a href="mailto:support@getkiwidrop.com">support@trykiwidrop.com</a>
              </span>
              <span style={{ fontWeight: 400 }}>&nbsp;o</span>
              <span style={{ fontWeight: 400 }}>r by mail using the details provided below:</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>Kiwidrop Inc, 227 Sandy Springs Pl NE Ste D-103, Sandy Springs, GA 30328</span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                We will make best efforts to respond in a reasonable period of time and may consult with third parties in addressing your inquiry.
                Additionally, you may have the right to provide inquiries directly to relevant data protection authorities, as may be applicable in
                your jurisdiction.
              </span>
            </p>
            <p>
              <i>
                <span style={{ fontWeight: 400 }}>Last Updated: Feb 7th 2022</span>
              </i>
            </p>
          </div>
        </div>
      </div>
    </main>
  )
}

export default RenderHtml
