import { useEffect, useState } from "react"

import CommonStyle from "../../../resources/styles/common.module.css"
import Styles from "./index.module.css"

const StepItem = (props: any) => {
  const { label, active, product } = props

  const setFailedActiveClass: any = (ary: any) => {
    if (product.request_id && active) {
      if (product.status === "FAILED") {
        return `failed${ary}`
      }
    }
  }

  return (
    <div className={`${CommonStyle.row} ${Styles.stepItem}`}>
      <div className={`${Styles.bullet} ${active ? Styles.active_bullet : ""} ${Styles[setFailedActiveClass("Bullet")]}`} />
      <div className={`${Styles.label} ${active ? Styles.active : ""} ${Styles[setFailedActiveClass("Label")]}`}>{label}</div>
    </div>
  )
}

const progressDefault = [
  {
    label: "Product Picked",
    active: true,
  },
  {
    label: "Processing Request",
    active: false,
  },
  {
    label: "View Result",
    active: false,
  },
]

const RequestProgressBar = (props: any) => {
  const [progress, setProgress] = useState(progressDefault)

  useEffect(() => {
    initProgressBar(props.product)
  }, [props.product])

  function initProgressBar(product: any) {
    // 如果有request 那么此商品至少为Processing Request 第二状态
    if (product.request_id) {
      // product.status 有三种状态 PROCESSING，SUCCESS，FAILED
      // PROCESSING为第二阶段 SUCCESS，FAILED均为第三接单
      if (product.status === "PROCESSING") {
        commonSetProgressActive(1)
      } else {
        commonSetProgressActive(2)
      }
    } else {
      // 如果商品没有status 默认商品为Picked 第一状态
      commonSetProgressActive(0)
    }
  }

  function commonSetProgressActive(activeIndex: any) {
    console.log(activeIndex)
    let newProgress = JSON.parse(JSON.stringify(progress))
    newProgress = newProgress.map((i: any) => ({ ...i, active: false }))
    newProgress[activeIndex].active = true
    setProgress(newProgress)
  }

  return (
    <div className={`${CommonStyle.column} ${Styles.progressbarView}`}>
      {progress.map((item: any, index: any) => (
        <StepItem product={props.product} key={index} label={item.label} active={item.active} />
      ))}
    </div>
  )
}

export default RequestProgressBar
