import React, { useEffect, useState } from "react"
import { useLocation, useParams, useSearchParams } from "react-router-dom"

import Container from "../../../components/Common/Container"
import Footer from "../../../components/Common/Footer"
import ProductDetail from "../../../components/ProductDetail"
import CommonStyle from "../../../resources/styles/common.module.css"
import { getProduct, getRequest } from "../../../services/pick"
import Styles from "./index.module.css"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RequestDetail = (props: any) => {
  const [product, setProduct] = useState(null)
  const params = useParams()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const id = params?.id
  const idType = searchParams.get("type")

  useEffect(() => {
    loadProduct()
  }, [id])

  useEffect(() => {
    // add `page-pick-request-detail` class mark to html element
    // to resolve request detail page mobile custom style
    document.documentElement.classList.add("page-pick-request-detail")
    return () => document.documentElement.classList.remove("page-pick-request-detail")
  }, [])

  // 如果有requestId 说明商品是已经发过request了 请求request详情
  // 如果有pickId 说明商品还未发过request 请求item详情
  // 两种接口返回的数据格式不一样，需要统一格式化一次
  const loadProduct = async () => {
    console.log(location.state)
    let product: any = null
    if (idType === "request") {
      getRequest(id).then(async (res) => {
        if (res) {
          product = await formatProduct(res, { hasRequset: true })
          setProduct(product)
        }
      })
    } else if (idType === "pick") {
      getProduct(id).then(async (res) => {
        if (res) {
          product = await formatProduct(res, { hasRequset: false })
          setProduct(product)
        }
      })
    }
  }

  const formatProduct = async (data: any, status: any) => {
    console.log("formatProduct", data)
    const dataSchema = {
      images: null,
      currency: null,
      tags: [],
      notes: null,
      // est_price: null,
      price: null,
      product_vendor: null,
      create_time: null,
      source_url: null,
      cover_image: null,
      title: null,
    }

    let fiteredData: any = null
    if (status.hasRequset) {
      console.log("已 request")

      const request = data
      const pick = request.pick || {}
      const item = pick.item || {}
      fiteredData = {
        ...dataSchema,
        request_id: request.id,
        pick_id: pick.id,
        item_id: item.id,
        pick_type: pick.pick_type,
        title: pick.title || item.title,
        images: (pick.images || []).length > 0 ? pick.images : item.images,
        cover_image: item ? item.cover_image : null,
        currency: item.currency,
        share_id: pick.share_id,

        // pick
        tags: pick.tags,
        notes: pick.notes,
        // est_price: item.est_price,
        price: item ? item.price : null,
        product_vendor: item ? item.product_vendor : null,
        source_url: item ? item.source_url : null,

        // request props
        status: request.status,
        destination_country: request.destination_country,
        plan_sell: request.plan_sell,
        plan_sell_currency: request.plan_sell_currency,
        anticipated_date: request.anticipated_date,
        product_category: request.product_category,
        quantity: request.quantity,
        order_type: request.order_type,
        accept_similar: request.accept_similar,
        product_url: request.product_url,
        source_urls: pick.source_urls,
        team_notes: request.team_notes,
        shopify_products: request.shopify_products,
        // failed 时 会返回Similar Products
        similar_products: request.similar_products,
        create_time: request.create_time,
      }
      delete fiteredData.item
    } else {
      console.log("未request")
      const pick = data
      const item = pick.item || {}
      fiteredData = {
        ...dataSchema,
        request_id: null,
        item_id: item.id,
        pick_id: pick.id,
        pick_type: pick.pick_type,
        title: pick.title || item.title,
        images: (pick.images || []).length > 0 ? pick.images : item.images,
        cover_image: item.cover_image,
        currency: item.currency,
        share_id: pick.share_id,

        tags: pick.tags,
        notes: pick.notes,
        // est_price: item.est_price,
        price: item.price,
        product_vendor: item.product_vendor,
        source_url: item.source_url,

        create_time: item.create_time,
      }
    }

    console.log("formatProduct result: ", fiteredData)
    return fiteredData
  }

  // @ts-ignore
  if (!product) {
    return null
  }

  return (
    <Container>
      <div className={CommonStyle.content}>
        <div className={Styles.detail}>
          <ProductDetail product={product} formatProduct={formatProduct} />
        </div>
      </div>
      <Footer />
    </Container>
  )
}

export default RequestDetail
