import React, { useState } from "react"

import BaseButton, { BaseButtonProps } from "../BaseButton"

const AutoLoadingButton = (props: BaseButtonProps) => {
  const onClick: any = props.onClick
  const [loading, setLoding] = useState(false)

  const handleClick: React.MouseEventHandler<HTMLElement> = async (e) => {
    setLoding(true)
    await onClick(e)
    setTimeout(() => {
      setLoding(false)
    }, 500)
  }

  return (
    <BaseButton {...props} onClick={handleClick} loading={loading}>
      {loading ? null : props.children}
    </BaseButton>
  )
}

export default AutoLoadingButton
