import { Input, Upload } from "antd"
import React from "react"

import { presignedUrl } from "../../../services/pick"
import Common from "../../../utils/common"
import Styles from "./index.module.css"

class Profile extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      ...this.props,
      editName: false,
      editPhoto: false,

      name: "",
      photo: "",
      defaultPhoto: "https://pickkiwi.s3.us-east-2.amazonaws.com/image/97dfe33299fd4291aa7d40d1c35c6546.png",
    }
  }

  uploadPhoto = () => {
    const uploadDom: any = document.querySelector("#uploadDom")
    uploadDom.click()
  }

  uploadHandleChange = async (event: any) => {
    Common.showLoading("Uploading image, please wait...")

    const s3Url = await presignedUrl().then((res) => {
      if (res) {
        return res.item
      }
    })
    const imgsUrl = await fetch(s3Url, {
      method: "PUT",
      headers: {
        "Content-Type": "image/png",
      },
      body: event.file,
    }).then((res) => {
      if (res.url) {
        return res.url.split("?")[0]
      }
    })
    this.setFormVal("photo", imgsUrl)
    Common.hideLoading()
  }

  editNameHandle = () => {
    this.setState({
      editName: !this.state.editName,
    })
  }

  setFormVal = (key: any, value: any) => {
    const newUserInfo = Object.assign({}, this.state.userInfo, {
      [key]: value,
    })
    this.setState({
      userInfo: newUserInfo,
    })
  }

  changeName = (e: any) => {
    this.setFormVal("name", e.target.value)
  }

  render() {
    const { userInfo, defaultPhoto, editName } = this.state
    return (
      <div className={Styles.mainProfile}>
        <h3 className={Styles.title}>Profile</h3>

        <div className={Styles.basicInfo}>
          <div className={Styles.photo}>
            <img className={Styles.photoImg} src={userInfo.photo || defaultPhoto} alt="" />
            <p className={Styles.editPhoto} onClick={this.uploadPhoto}>
              Edit Photo
            </p>
            <Upload
              accept=".png, .jpg, .jpeg"
              id="uploadDom"
              listType="picture-card"
              customRequest={this.uploadHandleChange}
              className={Styles.uploadHiddenDom}
            >
              <div className={Styles.uploadBtnHide} id="uploadBtnHide">
                Add Image
              </div>
            </Upload>
          </div>

          <div className={Styles.name}>
            {editName ? (
              <div className={Styles.userName}>
                <Input
                  onKeyPress={(e) => {
                    if (e.nativeEvent.keyCode === 13) {
                      this.editNameHandle()
                    }
                  }}
                  autoFocus
                  defaultValue={userInfo.name}
                  onChange={this.changeName}
                />
              </div>
            ) : (
              <div className={Styles.userName}>{userInfo.name || "-"}</div>
            )}
            <p className={Styles.editName} onClick={this.editNameHandle}>
              {editName ? "Save Name" : "Edit Name"}
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default Profile
