import { message } from "antd"

import AppCfg from "../configs/AppCfg"
import request from "../utils/request"

export const deleteItem = async (id: any) => {
  const rep = await request({
    url: `${AppCfg.API_SERVER_URL}/api/v2/pickkiwi/user_pick/${id}`,
    method: "DELETE",
  })

  if (rep.code === -1) {
    message.error(`${rep && rep.message ? rep.message : ""}`)
  }

  return rep
}

export const getPickList = async (args: any) => {
  const rep = await request(
    {
      url: `${AppCfg.API_SERVER_URL}/api/v2/pickkiwi/user_picks`,
      method: "POST",
    },
    args
  )

  return rep
}

export const getTags = async () => {
  const rep = await request({
    url: `${AppCfg.API_SERVER_URL}/api/v2/pickkiwi/tags`,
    method: "GET",
  })

  return rep
}

export const getProduct = async (id: any) => {
  const rep = await request({
    url: `${AppCfg.API_SERVER_URL}/api/v2/pickkiwi/user_pick/${id}`,
    method: "GET",
  })
  if (rep.code === -1) {
    message.error(`${rep && rep.message ? rep.message : ""}`)
    return null
  }
  return rep
}

export const addRequest = async (args: any) => {
  const rep = await request({
    url: `${AppCfg.API_SERVER_URL}/api/v2/pickkiwi/request/add`,
    method: "POST",
    args,
  })

  if (rep.code === -1) {
    message.error(`${rep && rep.message ? rep.message : ""}`)
    return null
  }

  return rep
}

export const getRequests = async (args: any) => {
  const rep = await request({
    url: `${AppCfg.API_SERVER_URL}/api/v2/pickkiwi/requests`,
    method: "POST",
    args,
  })

  if (rep.code === -1) {
    message.error(`${rep && rep.message ? rep.message : ""}`)
    return null
  }

  return rep
}

export const getRecentRequests = async () => {
  const rep = await request({
    url: `${AppCfg.API_SERVER_URL}/api/v2/pickkiwi/recently_requests`,
    method: "GET",
  })

  if (rep.code === -1) {
    message.error(`${rep && rep.message ? rep.message : ""}`)
    return null
  }

  return rep
}

export const getRequest = async (id: any) => {
  const rep = await request({
    url: `${AppCfg.API_SERVER_URL}/api/v2/pickkiwi/request/${id}`,
    method: "GET",
  })
  if (rep.code === -1) {
    message.error(`${rep && rep.message ? rep.message : ""}`)
    return null
  }
  return rep
}

export const uploadImage = async (args: any) => {
  const rep = await request({
    url: `${AppCfg.API_SERVER_URL}/api/v2/pickkiwi/image/upload`,
    method: "POST",
    args,
  })
  if (rep.code === -1) {
    message.error(`${rep && rep.message ? rep.message : ""}`)
    return null
  }
  return rep
}

export const sendValidation = async (args: any) => {
  const rep = await request({
    url: `${AppCfg.API_SERVER_URL}/api/v2/pickkiwi/account/send_validation_sms`,
    method: "POST",
    args,
  })
  if (rep.code === -1) {
    console.log(rep)
    message.error(`${rep && rep.message ? rep.message : ""}`)
    return null
  }
  return rep
}

export const editUser = async (args: any) => {
  const rep = await request({
    url: `${AppCfg.API_SERVER_URL}/api/v2/pickkiwi/user/edit`,
    method: "POST",
    args,
  })
  if (rep.code === -1) {
    console.log(rep)
    message.error(`${rep && rep.message ? rep.message : ""}`)
    return null
  }
  return rep
}

export const getUser = async () => {
  const rep = await request({
    url: `${AppCfg.API_SERVER_URL}/api/v2/pickkiwi/account/profile`,
    method: "GET",
  })
  if (rep.code === -1) {
    console.log(rep)
    message.error(`${rep && rep.message ? rep.message : ""}`)
    return null
  }
  return rep
}

export const presignedUrl = async () => {
  const rep = await request({
    url: `${AppCfg.API_SERVER_URL}/api/v2/pickkiwi/s3/presigned_url`,
    method: "GET",
  })
  if (rep.code === -1) {
    message.error(`${rep && rep.message ? rep.message : ""}`)
    return null
  }
  return rep
}

export const supportSite = async () => {
  const rep = await request({
    url: `${AppCfg.API_SERVER_URL}/api/v2/pickkiwi/support_site`,
    method: "GET",
  })
  if (rep.code === -1) {
    message.error(`${rep && rep.message ? rep.message : ""}`)
    return null
  }
  return rep
}
