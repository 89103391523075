import React from "react"
import { Route, Routes } from "react-router-dom"

import Account from "@/pages/Account"
import Chat from "@/pages/Chat"
import Home from "@/pages/Home"
import Login from "@/pages/Login2"
import NotFound from "@/pages/NotFound"
import NewRequest from "@/pages/PickKiwi/NewRequest"
import PickEntry from "@/pages/PickKiwi/PickEntry"
import PickList from "@/pages/PickKiwi/PickList"
import RequestDetail from "@/pages/PickKiwi/RequestDetail"
import RequestList from "@/pages/PickKiwi/RequestList"
import SuccessProducts from "@/pages/PickKiwi/SuccessProducts"
import SupportSites from "@/pages/PickKiwi/SupportSites"
import PrivacyPolicy from "@/pages/PrivacyPolicy"
import TeamLogin from "@/pages/TeamLogin"
import ShareBoard from "@/pages/ThirdShare/ShareBoard"
import ShareDetail from "@/pages/ThirdShare/ShareDetail"
import ShareGroup from "@/pages/ThirdShare/ShareGroup"
import SharePicks from "@/pages/ThirdShare/SharePicks"
import UserSpace from "@/pages/UserSpace"

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route index element={<Home />} />

    {/* Messages */}
    <Route path="/Chat" element={<Chat />} />

    {/* User related */}
    <Route path="/Login" element={<Login />} />
    <Route path="/TeamLogin" element={<TeamLogin />} />
    <Route path="/UserSpace" element={<UserSpace />} />

    {/* Social share */}
    <Route path="/ShareBoard/:id" element={<ShareBoard />} />
    <Route path="/ShareDetail/:id" element={<ShareDetail />} />
    <Route path="/ShareGroup" element={<ShareGroup />} />
    <Route path="/SharePicks/:userId" element={<SharePicks />} />

    {/* Pick Kiwi */}
    <Route path="/Pick" element={<PickEntry />}>
      <Route index element={<PickList />} />
      <Route path="/Pick/PickList" element={<PickList />} />
      <Route path="/Pick/RequestList" element={<RequestList />} />
      <Route path="/Pick/RequestDetail/:id" element={<RequestDetail />} />
      <Route path="/Pick/SuccessProducts" element={<SuccessProducts />} />
      <Route path="/Pick/NewRequest" element={<NewRequest />} />
      <Route path="/Pick/Account" element={<Account />} />
      <Route path="/Pick/SupportSites" element={<SupportSites />} />
    </Route>

    <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />

    <Route path="/privacy.html" element={<PrivacyPolicy />} />

    <Route path="*" element={<NotFound />} />
  </Routes>
)

export default AppRoutes
