import { Button, message } from "antd"
import { useEffect, useRef } from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"

import AutoLoadingButton from "../../components/Common/AutoLoadingButton"
import Container from "../../components/Common/Container"
import { updateCurrentUser } from "../../redux/app/actions"
import CommonStyle from "../../resources/styles/common.module.css"
import { editUser } from "../../services/pick"
import Logins from "./components/Logins"
import Notifications from "./components/Notifications"
import Profile from "./components/Profile"
import Styles from "./index.module.css"

const AccountIndex = (props: any) => {
  const { userInfo } = props

  const ProfileFormRef: any = useRef(Object)
  const LoginsFormRef: any = useRef(Object)
  const NotificationsFormRef: any = useRef(Object)
  const navigate = useNavigate()

  useEffect(() => {
    // add `page-account` class mark to html element
    // to resolve new request page mobile custom style
    document.documentElement.classList.add("page-account")
    return () => document.documentElement.classList.remove("page-account")
  }, [])

  const handleBack = (e: React.MouseEvent) => {
    // go back
    navigate(-1)
  }

  const accountFormSubmit = () => {
    const ProfileFormVal = ProfileFormRef.current.state.userInfo
    const LoginsFormVal = LoginsFormRef.current.state
    const NotificationsFormVal = NotificationsFormRef.current.state.userInfo

    const sendBody = {
      // profile
      photo: ProfileFormVal.photo,
      name: ProfileFormVal.name,

      // logins
      phone: LoginsFormVal.editPhone ? LoginsFormVal.userInfo.phone : undefined,
      phone_code: LoginsFormVal.editPhone ? Number(LoginsFormVal.userInfo.verificationCode) : undefined,

      // notifications
      email_notification: NotificationsFormVal.email_notification,
      phone_notification: NotificationsFormVal.phone_notification,
    }

    finalCurlApi(sendBody)
  }

  const finalCurlApi = (sendBody: any) => {
    editUser(sendBody).then((newUserInfo) => {
      if (newUserInfo) {
        message.success("change success")
        // 更新仓库中的用户信息
        props.dispatch(updateCurrentUser(newUserInfo))
      }
    })
  }

  return (
    <Container>
      <div className={Styles.mainAccountIndex}>
        <div className={Styles.mobileNavBar}>
          <Button type="text" icon={<span className="iconfont icon-exit" />} onClick={handleBack} />
        </div>
        <Profile userInfo={userInfo} ref={ProfileFormRef} />
        <Logins userInfo={userInfo} ref={LoginsFormRef} />
        {/* <ConnectedStores /> */}
        <Notifications userInfo={userInfo} ref={NotificationsFormRef} />
        <div className={Styles.submit}>
          <AutoLoadingButton className={CommonStyle.submitBtn} shape={"round"} onClick={accountFormSubmit}>
            Save Changes
          </AutoLoadingButton>
        </div>
      </div>
    </Container>
  )
}

function mapStateToProps(state: any) {
  return {
    userInfo: state.app.currentUser.user,
  }
}

export default connect(mapStateToProps)(AccountIndex)
