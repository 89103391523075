/* eslint-disable react/no-unescaped-entities */
import cn from "classnames"
import React from "react"

import styles from "./Empty.module.css"

export interface Props {
  /** The component class name */
  className?: string

  /** The component inline style */
  style?: React.CSSProperties

  hasFilter: boolean
}

/**
 * Empty list state tips
 */
function Empty({ className, style, hasFilter, ...restProps }: Props): JSX.Element {
  return (
    <div className={cn(styles.container, className)} style={style} {...restProps}>
      <div className={styles.content}>
        <p className={styles.tips}>
          <i>😳</i>
          <strong>No products yet </strong>
          Please wait for our sourcing team if you've already sent a request. <br />
          We're sourcing your products now!
        </p>
      </div>
    </div>
  )
}

export default Empty
