import { message } from 'antd'

/**
 * Common utils
 */
export default class Common {
  static sleep(time) {
    return new Promise((resolve) => setTimeout(() => resolve(), time))
  }

  static toast(msg, type = 'info') {
    if (typeof msg !== 'string') msg = JSON.stringify(msg)
    message[type](msg, 3, null)
  }

  static showLoading(msg = 'Loading, please wait...') {
    message.loading({
      content: msg,
      duration: 0,
      style: {
        marginTop: '20vh',
      },
    })
  }

  static hideLoading() {
    message.destroy()
  }

  static checkMail(email) {
    // eslint-disable-next-line no-useless-escape
    const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
    if (filter.test(email)) {
      return true
    }
    return false
  }

  static copyToClipboard(text) {
    // @ts-ignore
    if (window.clipboardData && window.clipboardData.setData) {
      // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
      // @ts-ignore
      return window.clipboardData.setData('Text', text)
    }

    if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
      const textarea = document.createElement('textarea')
      textarea.textContent = text
      textarea.style.position = 'fixed' // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea)
      textarea.select()
      try {
        return document.execCommand('copy') // Security exception may be thrown by some browsers.
      } catch (ex) {
        console.warn('Copy to clipboard failed.', ex)
        return false
      } finally {
        document.body.removeChild(textarea)
      }
    }
  }
}
