import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import { Carousel, Image } from "antd"
import _ from "lodash"
import React, { useState } from "react"

import BaseButton from "@/components/Common/BaseButton"
import Dialog from "@/components/Common/Dialog"
import CommonStyle from "@/resources/styles/common.module.css"
import { convertToUSD } from "@/utils/currency"

import RequestForm from "./DialogForm/RequestForm"
import Styles from "./index.module.css"

const ProductItem = (props: any) => {
  const { product, onRequest } = props

  return (
    <div className={Styles.similarPrdItem}>
      {!_.isEmpty(product.images) && (
        <Image.PreviewGroup>
          <Carousel dots={false} arrows={true} prevArrow={<LeftOutlined />} nextArrow={<RightOutlined />}>
            {product.images?.map((src: string) => (
              <Image key={src} className={Styles.similarPrdImage} src={src} />
            ))}
          </Carousel>
        </Image.PreviewGroup>
      )}

      <div className={Styles.similarPrdItemFooter}>
        {/* <div className={Styles.similarPrdCur}>${convertToUSD(product.price, product.currency).amount}</div> */}
        <BaseButton color={"black"} className={Styles.similarRequestBtn} shape="round" onClick={() => onRequest?.(product)}>
          Request
        </BaseButton>
      </div>
    </div>
  )
}

const SimilarProducts = (props: any) => {
  const [requestFormVisible, setRequestFormVisible] = useState(false)
  const [requestFormProduct, setRequestFormProduct] = useState(null)

  const { similarProducts, teamNotes } = props

  const handleShowRequestForm = (product: any) => {
    product.item_id = product.id
    setRequestFormProduct(product)
    setRequestFormVisible(true)
  }

  const handleHideRequestForm = () => {
    setRequestFormVisible(false)
  }

  return (
    <div className={`${CommonStyle.card} ${Styles.similarPrdView}`}>
      <div className={`${CommonStyle.t6} ${CommonStyle.tb}`}>Similar Products</div>
      {teamNotes && <div className={CommonStyle.t4}>{teamNotes}</div>}
      <div className={Styles.similarPrdImgView}>
        {(similarProducts || []).map((sp: any) => (
          <ProductItem key={sp.id} product={sp} onRequest={handleShowRequestForm} />
        ))}
      </div>

      <Dialog title="Request Drops" visible={requestFormVisible} maskClosable closeModal={handleHideRequestForm} style={{ top: 20 }} destroyOnClose>
        <RequestForm from={"similarProducts"} product={requestFormProduct} closeModal={handleHideRequestForm} />
      </Dialog>
    </div>
  )
}

export default SimilarProducts
