import * as actions from "./constant"

export const init = (payload: any) => (dispatch: any) => {
  dispatch({
    type: actions.INIT,
    payload,
  })
}

export const setCurrentUser = (payload: any) => (dispatch: any) => {
  dispatch({
    type: actions.SET_CURRENT_USER,
    payload,
  })
}

export const updateCurrentUser = (payload: any) => (dispatch: any, getState: any) => {
  const newPayload = {
    ...getState().app.currentUser,
    user: {
      ...payload,
    },
  }

  console.log(newPayload)
  dispatch({
    type: actions.UPDATE_CURRENT_USER,
    payload: newPayload,
  })
}

export const receivedMessages = (payload: any) => (dispatch: any) => {
  dispatch({
    type: actions.RECEIVED_MESSAGES,
    payload,
  })
}
