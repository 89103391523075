import "raf/polyfill"
import "./index.css"
import "./resources/styles/theme.css"
import "./resources/styles/antd.css"
import "./resources/font/iconfont.css"

import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import { ConfigProvider } from "antd"
import enUS from "antd/es/locale/en_US"
import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"

import AppCfg from "@/configs/AppCfg"

import App from "./App"
import reportWebVitals from "./reportWebVitals"

Sentry.init({
  dsn: "https://d162e648d0c344d99a028bc2ad789e57@o1208560.ingest.sentry.io/6409787",
  integrations: [new BrowserTracing()],
  environment: AppCfg.APP_ENV,
  // debug: appEnv === 'dev',

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: AppCfg.APP_ENV === "dev" ? 1.0 : 0.2,
})

function WrappedApp() {
  return (
    <React.StrictMode>
      <ConfigProvider locale={enUS}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ConfigProvider>
    </React.StrictMode>
  )
}

ReactDOM.render(<WrappedApp />, document.getElementById("root"))

console.log("process.env.NODE_ENV", process.env.NODE_ENV)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
