import { usdExchangeRates } from "@/configs/Constants"

export type Currency = {
  code: keyof typeof usdExchangeRates
  symbol: string
  amount: number | string
}

/**
 * convert currency to USD currency
 * @param amount currency amount
 * @param code currency code
 * @param decimals currency amount decimals
 * @returns {Currency} converted USD currency object
 */
export function convertToUSD(amount: Currency["amount"], code?: Currency["code"], decimals = 2): Currency {
  const exchangeRate = usdExchangeRates[code ?? "USD"] ?? usdExchangeRates.USD
  const usdMount = (Number(amount) / exchangeRate.rate).toFixed(decimals)

  return {
    code: "USD",
    symbol: usdExchangeRates.USD.symbol,
    amount: usdMount,
  }
}
