import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import Container from "@/components/Common/Container"
import Footer from "@/components/Common/Footer"
import TitleBar from "@/components/Common/TitleBar"

import RequestFormEntry from "./components/RequestFormEntry"
import Styles from "./index.module.css"

const NewRequest = () => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState(window.location.search)
  const tabsClickHandler = (type: any) => {
    navigate(`/Pick/NewRequest?tab=${type}`)
    setActiveTab(window.location.search)
  }

  const backTab = () => {
    navigate(`/Pick/NewRequest`)
    setActiveTab("")
  }

  useEffect(() => {
    setActiveTab(window.location.search)
  })

  useEffect(() => {
    // add `page-pick-new-request` class mark to html element
    // to resolve new request page mobile custom style
    document.documentElement.classList.add("page-pick-new-request")
    return () => document.documentElement.classList.remove("page-pick-new-request")
  }, [])

  return (
    <Container>
      <div className={Styles.newRequestMain}>
        <TitleBar title={"New Request"} style={{ paddingTop: 10 }} />
        {activeTab ? (
          <div>
            {/* add request form  */}
            <RequestFormEntry backTab={backTab} />
          </div>
        ) : (
          <div>
            <div className={Styles.newRequestTitle}>
              <span>Create a new</span> product sourcing request
            </div>
            <div className={Styles.newRequestSubTitle}>How do you want to start your request?</div>
            {/* choose type tabs */}
            {/* 三种类型的requset 1.images 2.urls 3. sync_store  第3种v2做 此版本1~2 */}
            <div className={Styles.chooseTabs}>
              <div className={Styles.tab} onClick={tabsClickHandler.bind(this, "images")}>
                <span className="iconfont icon-image" /> Request with images
              </div>
              <div className={Styles.tab} onClick={tabsClickHandler.bind(this, "urls")}>
                <span className="iconfont icon-link" /> Request with URLs
              </div>
              {/* <div className={Styles.tab} onClick={tabsClickHandler.bind(this, 'sync_store')}>
                  Sync from store request
                </div> */}
            </div>
          </div>
        )}
      </div>
      <Footer />
    </Container>
  )
}

export default NewRequest
