import "./index.css"

import { Button } from "antd"
import _ from "lodash"
import moment from "moment"
import { useNavigate } from "react-router-dom"

import useMediaQuery from "@/hooks/useMediaQuery"
import CommonStyle from "@/resources/styles/common.module.css"

import Styles from "./index.module.css"
const RequestStatus = (props: any) => {
  return <span className={`${Styles.statusText} ${props.status}ReqItem`}>{props.status === "FAILED" ? "FEEDBACK" : props.status}</span>
}

const RequestItem = (props: any) => {
  const { request, onViewProduct } = props
  const { pick: item } = request
  const isMobile = useMediaQuery("(max-width: 800px)")
  const navigate = useNavigate()

  const goRequsetDetail = () => {
    console.log(request.id)
    navigate(`/Pick/RequestDetail/${request.id}?type=request`, {
      state: {
        requestId: request.id,
        from: "requsetList",
      },
    })
  }

  const handleViewProduct = (e: React.MouseEvent) => {
    e.stopPropagation()
    onViewProduct?.(request)
  }

  const renderRequestCover = (item: any) => {
    if (item.item) {
      // pick
      return (
        <div className={CommonStyle.imgHoverZoom}>
          <img className={Styles.productImage} src={item.item.cover_image} />
        </div>
      )
    } else {
      if (item.images && item.images.length > 0) {
        // imgs
        return (
          <div className={CommonStyle.imgHoverZoom}>
            <img className={Styles.productImage} src={item.images[0]} />
          </div>
        )
      } else {
        // urls
        return (
          <div className={Styles.URLs}>
            <span className="iconfont icon-link" /> URL
          </div>
        )
      }
    }
  }

  return (
    <div className={`${CommonStyle.column} ${Styles.itemView}`} onClick={goRequsetDetail}>
      {
        // 根据Item渲染不同来源的requset 封面图
        renderRequestCover(item)
      }

      <div className={`${CommonStyle.column} ${Styles.itemContent}`}>
        <div className={CommonStyle.row}>
          <div>
            <div className={Styles.subTitle}>{item.title || "-"}</div>
            <div className={Styles.infoText}>{item.type}</div>
          </div>
          <div className={`${Styles.infoText} ${Styles.time}`}>
            <span className={Styles.dateLabel}>Requested: </span>
            {request.create_time ? moment(request.create_time).format(isMobile ? "lll" : "LLL") : "-"}
          </div>
        </div>
        <div className={`${CommonStyle.row} ${Styles.actionView}`}>
          <div className={Styles.statusWrapper}>
            <RequestStatus status={request.status} />
          </div>
          <div className={Styles.buttonWrapper}>
            {request.status === "SUCCESS" && !_.isEmpty(request.shopify_products) && (
              <Button className={Styles.btn} shape="round" onClick={handleViewProduct}>
                View Product
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RequestItem
