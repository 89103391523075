import React from "react"

const Chat = () => {
  return (
    <div style={{ border: "solid 1px #ccc" }}>
      <span>Chat UI</span>
    </div>
  )
}

export default Chat
