import { Button } from "antd"
import cn from "classnames"
import React from "react"
import { Link } from "react-router-dom"

import styles from "./Empty.module.css"

export interface Props {
  /** The component class name */
  className?: string

  /** The component inline style */
  style?: React.CSSProperties

  hasFilter: boolean
}

/**
 * Empty list state tips
 */
function Empty({ className, style, hasFilter, ...restProps }: Props): JSX.Element {
  const mobileGuide = (
    <div className={styles.newRequestGuide}>
      Or click here to start a sourcing <br />
      request from scratch!
    </div>
  )

  if (hasFilter) {
    return (
      <div className={cn(styles.container, className)} style={style} {...restProps}>
        <div className={styles.content}>
          <p className={styles.tips}>
            <i>🔍</i>
            <strong>No results found</strong>
            Try using different keywords or filters and search again.
          </p>

          {mobileGuide}
        </div>
      </div>
    )
  }

  return (
    <div className={cn(styles.container, className)} style={style} {...restProps}>
      <div className={styles.content}>
        <p className={styles.tips}>
          <i>😳</i>
          <strong>You haven’t requested a product yet.</strong>
          You can start product sourcing requests <br />
          from a pick in My Picks
          <span className={styles.hideOnMobile}>
            , or through the <br />
            +New request above!
          </span>
        </p>

        <Link to="/Pick/PickList">
          <Button type="primary" className={styles.navigateButton} size="large" shape="round">
            Go to My Picks
          </Button>
        </Link>

        {mobileGuide}
      </div>
    </div>
  )
}

export default Empty
