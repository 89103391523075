import { Button, Input, message } from "antd"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import PhoneInput from "react-phone-input-2"
import { connect } from "react-redux"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

import BaseButton from "@/components/Common/BaseButton"
import Dialog from "@/components/Common/Dialog/index"
import { editUser } from "@/services/pick"

import { setCurrentUser, updateCurrentUser } from "../../../redux/app/actions"
import { login, sendValidationSms } from "../../../services/account"
import { getAutoLogin, setAutoLoginInfo } from "../../../utils/authority"
import Styles from "./index.module.css"

let timer: any = null

declare global {
  interface Window {
    kiwiPlugin_setAutoLoginInfo: any
  }
}

function PhoneForm(props: any) {
  const [step, setStep] = useState(1)

  const [mobile, setMobile] = useState("")
  const [getCodeing, setGetCodeing] = useState(false)

  const [code, setCode] = useState("")
  const [sendcodeing, setSendcodeing] = useState(false)
  const [nickNameVisible, setNickNameVisible] = useState(false)
  const [nickName, setNickName] = useState("")

  let [codeTimeout, setCodeTimeout] = useState(0)
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  // 向window挂载 设置用户信息 同步到redux和缓存的方法 为了让插件调用
  window.kiwiPlugin_setAutoLoginInfo = function (rep: any) {
    console.log("kiwiPlugin_setAutoLoginInfo", rep)
    if (rep && rep.token && rep.shopify_token) {
      // submit shopify multipass request
      setAutoLoginInfo(rep)
      props.dispatch(setCurrentUser(rep))
      gotoHomePage()
    } else {
    }
  }

  useEffect(() => {
    if (codeTimeout === 60) {
      let newCodeTimeout = codeTimeout--
      setCodeTimeout(newCodeTimeout)
      timer = setInterval(() => {
        newCodeTimeout = codeTimeout--
        setCodeTimeout(newCodeTimeout)
        if (newCodeTimeout === 0) {
          clearInterval(timer)
          setCodeTimeout(0)
        }
      }, 1000)
    }

    if (codeTimeout === 0) {
      clearInterval(timer)
      setCodeTimeout(0)
    }
  }, [codeTimeout])

  const handleMobileChange = (value: any) => {
    if (value.indexOf("+86") > -1 || value.indexOf("+852") > -1 || value.indexOf("+886") > -1 || value.indexOf("+853") > -1) {
      message.info("Service is not available in the selected region")
      return
    }
    setMobile(value)
  }
  const sendCode = async () => {
    setGetCodeing(true)
    const result = await sendValidationSms({ phone: `+${mobile}` })
    if (result != "ok") {
      message.error("SMS validation code send failed!")
      setGetCodeing(false)
      return
    }
    setGetCodeing(false)
    setStep(2)
  }

  const reSendCode = async () => {
    if (codeTimeout === 0) {
      setCodeTimeout(60)
      const result = await sendValidationSms({ phone: `+${mobile}` })
      if (result != "ok") {
        message.error("SMS validation code send failed!")
        setGetCodeing(false)
        setCodeTimeout(0)
      }
    }
  }

  const handleLogin = async () => {
    setSendcodeing(true)
    const rep = await login({ phone: mobile, code, username: "", password: "" })
    if (rep && rep.token && rep.shopify_token) {
      // submit shopify multipass request
      setAutoLoginInfo(rep)
      props.dispatch(setCurrentUser(rep))
    } else {
      message.warn("login failed: " + rep.message)
      setSendcodeing(false)
      return
    }
    setSendcodeing(false)

    if (!rep.nameCheck) {
      setNickNameVisible(true)
    } else {
      gotoHomePage()
    }
  }

  const gotoHomePage = () => {
    navigate(searchParams.get("redirect") || _.get(location, ["state", "next_url"]) || "/Pick")
  }

  const confirmNickName = () => {
    const sendBody = {
      name: nickName,
    }
    editUser(sendBody).then((newUserInfo) => {
      if (newUserInfo) {
        message.success("Nickname added!")
        closeNickNameModal()
        props.dispatch(updateCurrentUser(newUserInfo))
        gotoHomePage()
      }
    })
  }

  const closeNickNameModal = () => {
    setNickNameVisible(false)
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div>
            <div className={`${Styles.loginInput} ${Styles.step1}`}>
              <PhoneInput
                inputProps={{ className: `ant-input ${Styles.input}`, placeholder: "Enter your phone number" }}
                value={mobile}
                country={"us"}
                enableLongNumbers={true}
                preferredCountries={["us", "gb", "au", "ca"]}
                excludeCountries={["cn", "hk", "tw", "mo"]}
                countryCodeEditable={false}
                onChange={handleMobileChange}
              />
            </div>
            <div className={Styles.handleBtnBox}>
              <BaseButton loading={getCodeing} onClick={sendCode} color={"black"} shape={"round"} disabled={!mobile}>
                Continue
              </BaseButton>
            </div>
          </div>
        )

      case 2:
        return (
          <div>
            <div className={`${Styles.step2}`}>
              <div className={Styles.step2Title}>Enter the code sent to +{mobile}</div>
              <div className={Styles.codeInput}>
                <Input
                  maxLength={6}
                  placeholder="000000"
                  value={code}
                  onChange={(event: any) => {
                    setCode(event.target.value)
                  }}
                />
              </div>
              <div className={`${Styles.handleBtnBox} ${Styles.handleBtnBoxStep2}`}>
                <BaseButton onClick={handleLogin} loading={sendcodeing} color={"black"} shape={"round"} disabled={!code}>
                  Enter Kiwipick
                </BaseButton>
              </div>

              <div className={Styles.resendBox}>
                <div className={`${Styles.resend} ${codeTimeout > 0 ? Styles.codeSending : ""}`} onClick={reSendCode}>
                  Resend Code {codeTimeout > 0 && <>({codeTimeout})</>}
                </div>
                <div className={Styles.back} onClick={() => setStep(1)}>
                  Use Another Phone
                </div>
              </div>
            </div>
          </div>
        )
    }
  }

  return (
    <div>
      <div className={Styles.loginContainer}>
        <div className={Styles.loginTitle}>Welcome to KiwiPick!</div>
        <div className={Styles.loginSection}>{renderStep()}</div>
      </div>

      <Dialog title=" Nickname Required" visible={nickNameVisible} closeModal={closeNickNameModal} maskClosable>
        <div className={Styles.nicknameContainer}>
          <p className={Styles.nicknameTit}>Please enter a nickname for your pickkiwi account</p>
          <Input
            placeholder="enter a nickname"
            value={nickName}
            onChange={(e) => {
              setNickName(e.target.value)
            }}
          />
          <div className={Styles.nickNameSubmit}>
            <Button onClick={confirmNickName}>confirm</Button>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default connect()(PhoneForm)
