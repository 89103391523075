/**
 * Constants
 */

export default {
  PAGE_SIZE: 10,
}

export const usdExchangeRates = {
  USD: {
    code: "USD",
    symbol: "$",
    rate: 1,
    inverseRate: 1,
  },
  CNY: {
    code: "CNY",
    symbol: "￥",
    rate: 6.3455836169287,
    inverseRate: 0.15758991770784,
  },
  EUR: {
    code: "EUR",
    symbol: "€",
    rate: 0.88148416485942,
    inverseRate: 1.1344503280549,
  },
  HKD: {
    code: "HKD",
    symbol: "HK$",
    rate: 7.7925009637147,
    inverseRate: 0.12832850514314,
  },
  GBP: {
    code: "EUR",
    symbol: "£",
    rate: 0.73382838267906,
    inverseRate: 1.3627164383438,
  },
}
