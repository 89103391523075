import React from "react"

const ShareGroup = () => {
  return (
    <div style={{ border: "solid 1px #ccc" }}>
      <span>ShareGroup</span>
    </div>
  )
}

export default ShareGroup
