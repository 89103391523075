import "./css/UserTags.css"

import { Button, Tag } from "antd"
import React from "react"
import ScrollContainer from "react-indiana-drag-scroll"

import { getTags } from "../../../../services/pick"

class UserTags extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = { tags: [], sendTags_ids: [], searchSpread: false, search: "" }
  }

  render() {
    const { tags, searchSpread } = this.state
    if (!(tags && tags.length > 0)) {
      return null
    }

    return (
      <ScrollContainer>
        <div className="tagsMain">
          {searchSpread ? (
            <span className="mobileSearchIpt">
              <span className="iconfont icon-search iptSearchIcon"></span>
              <span className="iconfont icon-exit iptClearIcon" onClick={this.clearSearch}></span>
              <input type="text" onKeyPress={this.handleEnterKey} autoFocus onChange={this.changeSearch.bind(this)} />
            </span>
          ) : (
            <span className="mobileSearch">
              <Button
                onClick={() => {
                  this.setState({ searchSpread: true })
                }}
                shape="circle"
                icon={<span className="iconfont icon-search" />}
              ></Button>
            </span>
          )}
          {tags.map((tag: any, index: any) => (
            <Tag className={tag.active ? "active" : ""} onClick={this.tagClick.bind(this, tag)} key={index}>
              {tag.name}
            </Tag>
          ))}
        </div>
      </ScrollContainer>
    )
  }

  getTags = () => {
    getTags().then((data) => {
      console.log(data.tags)
      this.setState({
        tags: data.tags,
      })
    })
  }

  changeSearch = (e: any) => {
    this.setState({
      search: e.target.value,
    })
  }

  handleEnterKey = (e: any) => {
    if (e.nativeEvent.keyCode === 13) {
      this.commonFilterFunc()
    }
  }

  clearSearch = () => {
    this.setState(
      {
        search: "",
        searchSpread: false,
      },
      () => {
        this.commonFilterFunc()
      }
    )
  }

  tagClick = (activeTag: any) => {
    const { tags } = this.state
    const newTags = tags.map((item: any) => {
      if (item.id === activeTag.id) {
        return {
          ...item,
          active: item.active ? !item.active : true,
        }
      } else {
        return item
      }
    })

    this.setState(
      {
        tags: newTags,
      },
      () => {
        const sendTags = this.state.tags.filter((tag: any) => tag.active)
        const sendTags_ids = sendTags.map((tag: any) => tag.id)
        this.setState(
          {
            sendTags_ids,
          },
          () => {
            this.commonFilterFunc()
          }
        )
      }
    )
  }

  commonFilterFunc = () => {
    const { search, sendTags_ids } = this.state
    const { upsterPickListArgs } = this.props

    upsterPickListArgs({
      tag_ids: sendTags_ids,
      search,
    })
  }

  componentDidMount() {
    this.getTags()
  }
}

export default UserTags
