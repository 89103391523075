import { Button } from "antd"
import _ from "lodash"
import moment from "moment"
import React, { useState } from "react"

import AutoLoadingButton from "@/components/Common/AutoLoadingButton"
import BaseButton from "@/components/Common/BaseButton"
import Dialog from "@/components/Common/Dialog/index"
import ProductsDialog from "@/components/Common/ProductsDialog"
import ShareDialog from "@/components/Common/ShareDialog"
import { requestStatusObj } from "@/resources/options"
import CommonStyle from "@/resources/styles/common.module.css"

import RequestForm from "./DialogForm/RequestForm"
import Styles from "./index.module.css"

const RequestStatusBar = (props: any) => {
  const { product, updateRequestStatus } = props

  const [visible, setVisible] = useState(false)
  const [shareDialogVisible, setShareDialogVisible] = useState(false)
  const [productsDialogVisible, setProductsDialogVisible] = useState(false)

  const formRef: any = React.createRef()

  const closeModal = (requestId: any) => {
    formRef.current.resetFields()
    setVisible(false)
    // 未传递值时，requestId是event对象 需要判断
    if (typeof requestId === "number") {
      // 更新productDetail/requestDetail
      updateRequestStatus(requestId)
    }
  }

  const setStatusTag = () => {
    const status = product.status
    switch (status) {
      case "PROCESSING":
        return <span className={Styles.processTag}>{requestStatusObj[status]}</span>
      case "SUCCESS":
        return <span className={Styles.successTag}>{requestStatusObj[status]}</span>
      case "FAILED":
        return <span className={Styles.failedTag}>{requestStatusObj[status]}</span>
    }
  }

  const handleShowShareDialog = () => {
    setShareDialogVisible(true)
  }

  const handleHideShareDialog = () => {
    setShareDialogVisible(false)
  }

  const handleShowProductsDialog = () => {
    setProductsDialogVisible(true)
  }

  const handleHideProductsDialog = () => {
    setProductsDialogVisible(false)
  }

  return (
    <div className={`${CommonStyle.column} ${CommonStyle.boxShadow} ${Styles.statusbarView}`}>
      <div className={CommonStyle.column}>
        {/* 根据product.request来判断 当前的商品是否已经requested，
            1.如果没有product.request_id，说明此商品是从pick list过来的，还未发送request，
            显示发送request 的按钮
            2.如果商品有product.request_id，说明此商品发生了request，可以根据具体的状态显示不同的文字
          */}
        {product.request_id ? (
          <div className={Styles.requestBasic}>
            {setStatusTag()}
            <div>Request ID {product.request_id}</div>
            <div>Sourced on {moment(product.create_time).format("LLLL")}</div>
          </div>
        ) : (
          <div className={Styles.pickedTitle}>Picked on {moment(product.create_time).format("LL")}</div>
        )}
      </div>
      <div className={CommonStyle.column} style={{ alignItems: "center" }}>
        {product.pick_id ? (
          <Button
            onClick={handleShowShareDialog}
            size="large"
            shape="circle"
            icon={<span className="iconfont icon-share1" />}
            style={{ marginRight: 10 }}
          />
        ) : null}
        {product.request_id ? (
          <></>
        ) : (
          // <div className={Styles.btnsBox}>
          //   {/* 如果状态为SUCCESS 显示去购买的标实 */}
          //   {product.status === 'SUCCESS' && !_.isEmpty(product.shopify_products) && (
          //     <BaseButton color={'black'} className={Styles.btn} shape="round" onClick={handleShowProductsDialog}>
          //       View Product
          //     </BaseButton>
          //   )}
          //   {/* <span className={`iconfont ${Styles.chatIcon}`}>&#xe613;</span> */}
          // </div>
          <AutoLoadingButton className={Styles.RequestBtn} color={"black"} shape={"round"} onClick={() => setVisible(true)}>
            Request Drops
          </AutoLoadingButton>
        )}
      </div>
      {!product.request_id && (
        <Dialog title="Request Drops" destroyOnClose visible={visible} closeModal={closeModal} maskClosable style={{ top: 20 }}>
          <RequestForm formRef={formRef} product={product} closeModal={closeModal} />
        </Dialog>
      )}

      {/* bottom fixed buttons mask layer */}
      {(!product.request_id || (product.status === "SUCCESS" && !_.isEmpty(product.shopify_products))) && <div className={Styles.buttonMask} />}

      {/* share dialog */}
      {product.pick_id && (
        <ShareDialog
          visible={shareDialogVisible}
          url={`${location.origin}/ShareDetail/${product?.share_id}`}
          images={(product?.images || product?.images_request)?.[0]}
          onClose={handleHideShareDialog}
        />
      )}

      {/* multiple products dialog */}
      {product.status === "SUCCESS" && !_.isEmpty(product.shopify_products) && (
        <ProductsDialog
          visible={productsDialogVisible}
          title="Successfully Sourced Products"
          products={product?.shopify_products ?? []}
          onClose={handleHideProductsDialog}
        />
      )}
    </div>
  )
}

export default RequestStatusBar
