import { Checkbox } from "antd"
import React from "react"

import Styles from "./index.module.css"

class Notifications extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      ...props,
    }
  }

  onChange = (key: any, e: any) => {
    const newUserInfo = Object.assign({}, this.state.userInfo, {
      [key]: e.target.checked,
    })

    this.setState({
      userInfo: newUserInfo,
    })
  }

  render() {
    const { userInfo } = this.state
    return (
      <div className={Styles.mainNotifications}>
        <h3 className={Styles.title}>Notifications</h3>

        <div className={Styles.notificationsForm}>
          <div className={Styles.notificationsFormItem}>
            <Checkbox defaultChecked={userInfo.email_notification || true} onChange={this.onChange.bind(this, "email_notification")}>
              Allow kiwi to send me feedback about my requests
            </Checkbox>
          </div>
        </div>
      </div>
    )
  }
}

export default Notifications
