import _ from "lodash"

import BaseButton from "@/components/Common/BaseButton"
import CommonStyle from "@/resources/styles/common.module.css"

import Styles from "./index.module.css"
function DropsForYou(props: any) {
  console.log(props)
  const { product } = props

  const hrefToSop = (href: any) => {
    if (href) {
      window.open(href)
    }
  }
  if (product.request_id && product.status === "SUCCESS" && !_.isEmpty(product.shopify_products)) {
    return (
      <div className={`${CommonStyle.column} ${CommonStyle.boxShadow} ${Styles.dropsView}`}>
        <div className={Styles.dropsMain}>
          <div className={Styles.dropsTit1}>Drops for you</div>
          <div className={Styles.dropsList}>
            {product.shopify_products.map((p: any, index: any) => {
              return (
                <div key={index} className={Styles.dropsItem}>
                  <img className={Styles.dropsImg} src={p.images[0]} alt="" />
                  <BaseButton color={"black"} className={Styles.dropsBtn} shape="round" onClick={hrefToSop.bind(event, p.shopify_product_url)}>
                    View Drop
                  </BaseButton>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default DropsForYou
