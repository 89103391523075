import _ from "lodash"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

import { updateCurrentUser } from "@/redux/app/actions"
import { getUser } from "@/services/pick"

import Header from "../../../components/Common/Header"
export type PickEntryProps = {
  userInfo?: any
}

const PickEntry = (props: any) => {
  const navigate = useNavigate()
  const location = useLocation()
  console.log(location)

  const hasLogin = () => {
    return props.userInfo && props.userInfo.token
  }

  useEffect(() => {
    console.log(props.userInfo)
    if (!hasLogin()) {
      navigate(`/Login?redirect=${encodeURIComponent(`${location.pathname}${location.search}`)}`)
    }
  }, [])

  useEffect(() => {
    if (hasLogin()) {
      getUser().then((userInfo) => {
        if (userInfo?.user) {
          console.log("loaded user info: ", { userInfo })
          props.dispatch(updateCurrentUser(userInfo?.user))
        }
      })
    }
  }, [location])

  if (!hasLogin()) {
    return null
  }

  return (
    <>
      <Header />
      <Outlet />
    </>
  )
}

export default connect((state) => ({
  userInfo: _.get(state, ["app", "currentUser"]),
}))(PickEntry)
