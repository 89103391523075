import AppCfg from "../configs/AppCfg"
import { setAutoLoginInfo } from "../utils/authority"
import request from "../utils/request"

export const sendValidationSms = async (args: any) => {
  const rep = await request(
    {
      url: `${AppCfg.API_SERVER_URL}/api/v2/pickkiwi/account/send_validation_sms`,
      method: "POST",
    },
    args
  )

  return rep
}

export const login = async (args: any) => {
  const rep = await request(
    {
      url: `${AppCfg.API_SERVER_URL}/api/v2/pickkiwi/account/login`,
      method: "POST",
    },
    args
  )

  return rep
}

export const logout = () => {
  setAutoLoginInfo(null)
}

export const sendValidationEmail = async (args: any) => {
  const rep = await request(
    {
      url: `${AppCfg.API_SERVER_URL}/api/v2/pickkiwi/account/send_validation_email`,
      method: "POST",
    },
    args
  )

  return rep
}

export const bindEmail = async (args: any) => {
  const rep = await request(
    {
      url: `${AppCfg.API_SERVER_URL}/api/v2/pickkiwi/account/bind_email`,
      method: "POST",
    },
    args
  )

  return rep
}
