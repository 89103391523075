import React from "react"

import CommonStyle from "../../../resources/styles/common.module.css"
import Styles from "./index.module.css"

const MyTagsList = (props: any) => {
  const { tags } = props
  return (
    <div>
      <span className={`${CommonStyle.t6} ${CommonStyle.tb}`}>Your Tags</span>
      <div className={Styles.tagList}>
        {(tags || []).map((t: any) => (
          <span className={Styles.tag} key={t}>
            {t.name || t}
          </span>
        ))}
      </div>
    </div>
  )
}

export default MyTagsList
