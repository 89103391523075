import React from "react"

const UserSpace = () => {
  return (
    <div style={{ border: "solid 1px #ccc" }}>
      <span>UserSpace page</span>
    </div>
  )
}

export default UserSpace
