import * as Sentry from '@sentry/react'
import axios from 'axios'
import _ from 'lodash'

import { logout } from '@/services/account'

import { getAutoLogin, getToken } from './authority'

/**
 * ajax request use axios
 * @param urlObj
 */
const request = async (urlObj = {}, args = {}, cancelObj = null) => {
  // console.log('request:', urlObj, args)

  try {
    const instance = axios.create()
    const CancelToken = axios.CancelToken

    // cancel
    const token = getToken()
    const config = {
      headers: {},
      cancelToken: new CancelToken(function executor(c) {
        if (cancelObj && typeof cancelObj === 'object') {
          cancelObj.cancel = c
        }
      }),
    }

    if (token) {
      config.headers.authorization = 'Bearer ' + token
    }

    // header
    if (_.isObject(urlObj.headers)) {
      for (const key in urlObj.headers) {
        if (urlObj.headers.hasOwnProperty(key)) {
          config.headers[key] = urlObj.headers[key]
        }
      }
    }

    if (urlObj.args) {
      args = Object.assign(urlObj.args, args)
    }

    // url params replace
    let uri = urlObj.url
    if (uri.indexOf(':') > -1) {
      uri = uri
        .split('/')
        .map((p) => {
          if (p.startsWith(':')) {
            const key = p.substr(1)
            if (args[key]) {
              p = args[key]
              delete args[key]
            }
          }

          return p
        })
        .join('/')
    }

    let res = null

    // console.log("====> request args: ", urlObj.method, uri, JSON.stringify(args));

    if (urlObj.method.toLowerCase() === 'get') {
      config.params = args
      res = await instance.get(uri, config)
    } else {
      config.method = urlObj.method
      config.url = uri
      config.data = args
      res = await instance.request(config)
    }

    // console.log("<==== response data: ", urlObj.method, uri, res.data);
    return res.data
  } catch (e) {
    // send the error to sentry
    Sentry.captureException(new Error('Request Error'), { extra: { response: e, loginInfo: getAutoLogin(), page: location.href } })

    if (e.response && e.response.status == 401) {
      logout()
      window.location.href = '/Login'
    }

    console.log(e.request.response)
    // console.error("<==== error happens: ", e);

    if (e.request) {
      if (e.request.response) {
        return {
          code: -1,
          message: JSON.parse(e.request.response).message,
        }
      }
    }

    if (e.message) {
      return { code: -1, message: e.message }
    } else {
      return { code: -1, message: 'network exception' }
    }
  }
}

export default request
