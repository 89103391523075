import React from "react"

import CommonStyle from "../../../resources/styles/common.module.css"
import Styles from "./index.module.css"

const MyNotes = (props: any) => {
  const { notes } = props

  return (
    <div className={Styles.notesView}>
      <span className={`${CommonStyle.t6} ${CommonStyle.tb}`}>Your Pick Notes</span>
      <div>{notes}</div>
    </div>
  )
}

export default MyNotes
