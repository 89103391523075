import React, { Component } from "react"
import { Input } from "antd"
import Styles from "./index.module.css"

import { supportSite } from "../../../services/pick"

import "./styles.css"

class SupportSites extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      sites: [],
      defaultSites: [],
    }
  }
  render() {
    let { sites } = this.state
    return (
      <div className={`${Styles.main} SupportSites`}>
        <p className={Styles.title}>Currently Supported Sites</p>
        <div className={Styles.sitesContainer}>
          <Input
            onChange={this.searchSites}
            className={Styles.sitesSearch}
            placeholder="Find a supported site"
            prefix={<i className={`${Styles.search} iconfont icon-search`} />}
          />

          <div className={Styles.sitesList}>
            {sites.map((site: any, index: any) => (
              <li>
                <a href={site} target="_blank" key={index}>
                  {site}
                </a>
              </li>
            ))}
          </div>
        </div>
      </div>
    )
  }

  searchSites = (e: any) => {
    let newSites = this.state.defaultSites.filter((item: any) => item.includes(e.target.value))
    this.setState({
      sites: newSites,
    })
  }

  getSupportSite = () => {
    supportSite().then((res) => {
      if (res) {
        this.setState({
          sites: res,
          defaultSites: res,
        })
      }
    })
  }

  componentDidMount() {
    this.getSupportSite()
  }
}

export default SupportSites
