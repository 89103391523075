import _ from "lodash"
import { useEffect } from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"

const Home = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate("/Pick")
  })

  return null
  // return (
  //   <Container>
  //     <Header />
  //     <iframe style={{ width: '100%', border: 0, height: 3500 }} src={'https://www.trykiwidrop.com/'}></iframe>
  //   </Container>
  // )
}

export default connect((state) => ({
  userInfo: _.get(state, ["app", "currentUser"]),
}))(Home)
