import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import { Carousel, Image } from "antd"

import Styles from "./index.module.css"

const Swiper = (props: any) => {
  const { images, onChange } = props
  return (
    <div>
      <Image.PreviewGroup>
        <Carousel
          style={{ width: "300px" }}
          arrows={true}
          prevArrow={<LeftOutlined />}
          nextArrow={<RightOutlined />}
          dots={false}
          afterChange={onChange}
        >
          {images &&
            images.length > 0 &&
            images.map((item: any, index: any) => {
              return (
                <div key={index}>
                  <Image preview={false} className={Styles.imageCover} src={item} width="100%" />
                </div>
              )
            })}
        </Carousel>
      </Image.PreviewGroup>
    </div>
  )
}

export default Swiper
