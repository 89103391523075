import { Spin } from 'antd'
import _ from 'lodash'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import Dialog from '@/components/Common/Dialog'
import RequestForm from '@/components/ProductDetail/components/DialogForm/RequestForm'

import Footer from '../../../../components/Common/Footer'
import { getPickList } from '../../../../services/pick'
import Styles from './css/ProductList.module.css'
import Empty from './Empty'
import ProductItem from './ProductItem'

class ProductList extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      items: [],
      page: 1,
      per_page: 35,

      filterArgs: undefined,
      allNum: 0,
      canCurlFlag: true,
      loading: true,

      requestFormVisible: false,
      requestFormProduct: null,
    }
  }

  handleHideRequestForm = (newReqId: any, product: any) => {
    this.setState({ requestFormVisible: false })
    // 如果有 newReqId 和 product 说明是在 pick 列表发起的 request 成功之后 需要更新 pick item 的状态（隐藏 request 的按钮）
    if (newReqId && product) {
      const newItems: any = this.state.items
      for (const item of newItems) {
        if (item.id == product.pick_id) {
          console.log(item)
          item.request_id = newReqId
        }
      }
      this.setState({
        items: newItems,
      })
    }
  }

  handleShowRequestForm = (product: any) => {
    let item = product
    if (product.pick_type == 'ITEM') {
      item = product.item
      // 不能使用 product id, 要使用 item id, 否则 Submit Request 会找不到 item
      // item.item_id = product.id
      item.item_id = item.id
    }

    item.pick_id = product.id
    this.setState({ requestFormProduct: item, requestFormVisible: true })
  }

  goRequsetDetail = (requestId: any) => {
    this.setState({ requestFormVisible: false }, () => {
      this.props.navigate(`/Pick/RequestDetail/${requestId}?type=request`, {
        state: {
          requestId: requestId,
          from: 'pickList',
        },
      })
    })
  }

  render() {
    const { items, loading, requestFormVisible, requestFormProduct } = this.state
    const colCount = Math.floor(window.innerWidth / 220)
    let placeholderCount = 0
    if (items) {
      placeholderCount = (items.length || []) % colCount < 1 ? 0 : colCount - (items.length % colCount)
    }

    return (
      <div className={Styles.mainContent}>
        {!loading && _.isEmpty(items) && <Empty />}

        <div className={Styles.productListView}>
          {items.map((item: any) => (
            <ProductItem item={item} removeItem={this.removeItem} key={item.id} onRequest={this.handleShowRequestForm} />
          ))}
          {/* placeholders */}
          {items && items.length > 0
            ? [1, 2, 3, 4, 5, 6].map((index: any) =>
                index > placeholderCount ? null : <div key={`placeholder-${index}`} className={Styles.placeholder}></div>
              )
            : null}
        </div>
        {loading ? (
          <Spin tip="Loading...">
            <div className={Styles.loading}></div>
          </Spin>
        ) : null}
        <Footer />
        <Dialog
          title="Request Drops"
          visible={requestFormVisible && requestFormProduct != null}
          closeModal={this.handleHideRequestForm}
          style={{ top: 20 }}
          destroyOnClose
          maskClosable
        >
          <RequestForm product={requestFormProduct} closeModal={this.handleHideRequestForm} onFinish={this.goRequsetDetail} />
        </Dialog>
      </div>
    )
  }

  getProductItems = async () => {
    this.setState({
      loading: true,
    })
    const { filterArgs, per_page } = this.state

    const reqBody = {
      page: 1,
      per_page: per_page,
      ...filterArgs,
    }

    return getPickList(reqBody)
      .then((data) => {
        const resData = data.items
        this.setState({
          items: resData,
          allNum: data.total,
        })

        this.props?.onLoad?.(resData)
      })
      .catch((err: any) => {
        console.log(err)
      })
      .then(() => {
        this.setState({
          loading: false,
        })
      })
  }

  removeItem = (item: any) => {
    console.log(item)
    const newItems = this.state.items.filter((_item: any) => _item.id !== item.id)

    console.log(newItems)
    this.setState({
      items: newItems,
    })
  }

  concatData = () => {
    this.setState({
      loading: true,
    })
    const { allNum, page, per_page, canCurlFlag, filterArgs } = this.state

    const maxPage = Math.ceil(allNum / per_page)
    if (page > maxPage - 1) {
      console.log('没有更多了')
      this.setState({
        loading: false,
      })
    } else {
      if (canCurlFlag) {
        this.setState(
          {
            canCurlFlag: false,
            page: this.state.page + 1,
          },
          () => {
            const reqBody = {
              page: this.state.page,
              per_page: this.state.per_page,
              ...filterArgs,
            }
            getPickList(reqBody)
              .then((data) => {
                const newItems = this.state.items.concat(data.items)
                this.setState({
                  items: newItems,
                  canCurlFlag: true,
                })
              })
              .catch((err: any) => {
                console.log(err)
              })
              .then(() => {
                this.setState({
                  loading: false,
                })
              })
          }
        )
      }
    }
  }

  moveToBrowserBottom = () => {
    let timerForDebounce: any = null // 为了防抖添加的 timer
    window.onscroll = () => {
      console.log('window.onscroll')
      if (timerForDebounce) clearTimeout(timerForDebounce)
      const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
      // gap 是为了计算偏差，有时候会有 1px 的偏差值
      const gap = Math.ceil(document.documentElement.clientHeight + scrollTop) - document.documentElement.scrollHeight
      if (document.documentElement.scrollHeight === Math.ceil(document.documentElement.clientHeight + scrollTop) || gap === 1) {
        timerForDebounce = setTimeout(() => {
          this.concatData()
        }, 200)
      }
    }
  }

  async componentDidMount() {
    await this.getProductItems()
    this.moveToBrowserBottom()
  }

  componentWillUnmount() {
    window.onscroll = null
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(_nextProps: any) {
    if (_nextProps.filterArgs !== this.state.filterArgs) {
      this.setState(
        {
          filterArgs: _nextProps.filterArgs,
          page: 1,
          per_page: this.state.per_page,
        },
        () => {
          this.getProductItems()
        }
      )
    }
  }
}

function withNavigate(props: any) {
  const navigate = useNavigate()
  return <ProductList {...props} navigate={navigate} />
}

export default withNavigate
