import { applyMiddleware, createStore } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import { persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage" // defaults to localStorage for web
import thunkMiddleware from "redux-thunk"

import rootReducer from "../reducer"

const persistConfig = {
  key: "root",
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer())

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunkMiddleware)))

export const persistor = persistStore(store)
export default store
