import { message } from "antd"

import AppCfg from "../configs/AppCfg"
import request from "../utils/request"

export const getShareDetail = async (id: string) => {
  const rep = await request({
    url: `${AppCfg.API_SERVER_URL}api/v2/pickkiwi/guest/pick/${id}`,
    method: "GET",
  })

  if (rep.code === -1) {
    message.error(`${rep && rep.message ? rep.message : ""}`)
    return null
  }

  return rep
}

export const vote = async (args: { pick_id: number; like: boolean }) => {
  const rep = await request({
    url: `${AppCfg.API_SERVER_URL}api/v2/pickkiwi/guest/like`,
    method: "POST",
    args,
  })

  if (rep.code === -1) {
    message.error(`${rep && rep.message ? rep.message : ""}`)
    return null
  }

  return rep
}

type ShareListParams = {
  group_id?: number
  user_id?: number
  tag_ids?: number[]
  username?: string
  page?: number
  per_page?: number
}

export const getShareList = async (args: ShareListParams) => {
  const rep = await request({
    url: `${AppCfg.API_SERVER_URL}api/v2/pickkiwi/guest/list`,
    method: "POST",
    args,
  })

  if (rep.code === -1) {
    message.error(`${rep && rep.message ? rep.message : ""}`)
    return null
  }

  return rep
}

type SharePicksListParams = {
  user_id?: number
  start_share_id?: string
  tag_ids?: number[]
  limit?: number
  offset?: number
}

export const getSharePicksList = async (args: SharePicksListParams) => {
  const rep = await request({
    url: `${AppCfg.API_SERVER_URL}api/v2/pickkiwi/guest/pick/list`,
    method: "POST",
    args,
  })

  if (rep.code === -1) {
    message.error(`${rep && rep.message ? rep.message : ""}`)
    return null
  }

  return rep
}

type ShareBoardParams = {
  share_id: string
  page?: number
  per_page?: number
  offset?: number
  limit?: number
}

export const getShareBoard = async (args: ShareBoardParams) => {
  const rep = await request({
    url: `${AppCfg.API_SERVER_URL}api/v2/pickkiwi/share_board/detail`,
    method: "POST",
    args,
  })

  if (rep.code === -1) {
    message.error(`${rep && rep.message ? rep.message : ""}`)
    return null
  }

  return rep
}
