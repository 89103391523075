import { Modal } from "antd"
import _ from "lodash"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"

import { setCurrentUser } from "../../../../redux/app/actions"
import { logout } from "../../../../services/account"
import Styles from "./index.module.css"
const { confirm } = Modal

const Dropdown = (props: any) => {
  const navigate = useNavigate()
  const navs = ["Account Settings", "Sign Out", "Download Plugin"]

  const handleDownloadPlugin = () => {
    window.open("https://chrome.google.com/webstore/detail/a-kiwi-pick/cmgglgjgcgmhjcmlkbcfannfenmhfmdh")
  }

  const handleLogout = () => {
    confirm({
      content: "Logging out already?",
      onOk() {
        // 调用插件的注销方法
        const plugInLogOutBtn: any = document.querySelector("#kiwi_logout_btn")
        if (plugInLogOutBtn) {
          plugInLogOutBtn.click()
        }

        logout()
        props.dispatch(setCurrentUser({}))
        navigate("/Login")
      },
    })
  }

  const handleNavItem = (index: any) => {
    console.log(index)

    switch (index) {
      case 0:
        navigate("/Pick/Account")
        break
      case 1:
        handleLogout()
        break
      case 2:
        handleDownloadPlugin()
        break
    }
  }

  const defaultPhoto = "https://pickkiwi.s3.us-east-2.amazonaws.com/image/97dfe33299fd4291aa7d40d1c35c6546.png"

  return (
    <div className={Styles.mainDropdown}>
      <div className={Styles.userBasic}>
        <img src={_.get(props.userInfo, "photo") || defaultPhoto} alt="" className={Styles.userPhoto} />
        <p>{_.get(props.userInfo, "name") || "-"}</p>
        <p className={Styles.divider}></p>
        <div className={Styles.navbar}>
          {navs.map((nav: any, index: any) => (
            <li className={Styles.navItem} key={index} onClick={handleNavItem.bind(this, index)}>
              {nav}
            </li>
          ))}
        </div>
      </div>
    </div>
  )
}

export default connect()(Dropdown)
