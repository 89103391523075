import { Button } from "antd"
import { ButtonProps } from "antd/lib/button/button"
import React from "react"

import Styles from "./index.module.css"

export declare type BaseButtonProps = {
  color?: string
} & ButtonProps

const BaseButton = (props: BaseButtonProps) => {
  let className = props.color === "white" ? Styles.white : props.color === "green" ? Styles.green : Styles.black
  if (props.className) {
    className = `${className} ${props.className}`
  }
  return <Button {...props} className={className} />
}

export default BaseButton
