import { Input, Select } from "antd"
import * as React from "react"

import { requestSortOpts } from "@/resources/options"

import Styles from "./css/SearchBar.module.css"

const { Option } = Select
class SearchBar extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      test: "SearchBar",
      search: "",
      sort_by: "-create_time",
    }
  }

  render() {
    return (
      <div className={Styles.SearchBarMain}>
        {/* search */}
        <div className={Styles.search}>
          <span className={Styles.title}>Search</span>
          <Input placeholder="cool bottles..." onChange={this.changeSearch.bind(this)} onKeyPress={this.handleEnterKey} />
        </div>
        {/* sort by */}
        <div className={Styles.sortBy}>
          <span className={Styles.title}>Sort by</span>
          <Select onChange={this.changeSort.bind(this)} defaultValue="-create_time" style={{ width: 242 }} bordered={false}>
            {requestSortOpts.map((opt: any, index: any) => (
              <Option value={opt.value} key={index}>
                {opt.label}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    )
  }

  changeSearch = (e: any) => {
    this.setState({
      search: e.target.value,
    })
  }

  handleEnterKey = (e: any) => {
    if (e.nativeEvent.keyCode === 13) {
      this.commonFilterFunc()
    }
  }

  changeSort = (value: any) => {
    this.setState(
      {
        sort_by: value,
      },
      () => {
        this.commonFilterFunc()
      }
    )
  }

  commonFilterFunc = () => {
    const { search, sort_by } = this.state
    const { upsterPickListArgs } = this.props

    upsterPickListArgs({
      search: search || undefined,
      sort_by,
    })
  }
}

export default SearchBar
