import _ from 'lodash'

const getToken = () => {
  if (global.token) {
    return global.token
  }

  const infoJson = localStorage ? localStorage.getItem('kiwi-autologin') : ''
  if (!infoJson) {
    return ''
  }

  let autoLoginInfo
  try {
    autoLoginInfo = JSON.parse(infoJson)
    global.token = _.get(autoLoginInfo, 'token', '')
  } catch (e) {
    return ''
  }

  return global.token
}

const getAutoLogin = () => {
  const infoJson = localStorage ? localStorage.getItem('kiwi-autologin') : ''
  if (!infoJson) {
    return ''
  }

  let autoLoginInfo
  try {
    autoLoginInfo = JSON.parse(infoJson)
    return autoLoginInfo
  } catch (e) {
    return ''
  }
}

const setAutoLoginInfo = (autoLoginInfo) => {
  if (autoLoginInfo && typeof autoLoginInfo === 'object') {
    autoLoginInfo = JSON.stringify(autoLoginInfo)
  }

  global.token = autoLoginInfo ? autoLoginInfo.token : ''
  localStorage.setItem('kiwi-autologin', autoLoginInfo || '')
}

export { getAutoLogin, getToken, setAutoLoginInfo }
