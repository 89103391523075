import { Button, ButtonProps } from "antd"
import cn from "classnames"
import React from "react"
import { useNavigate } from "react-router-dom"

import styles from "./index.module.css"

export interface Props {
  /** The component class name */
  className?: string

  /** The component inline style */
  style?: React.CSSProperties

  /** The title text */
  title?: React.ReactNode

  /** The suffix element */
  suffix?: React.ReactNode

  /** The back button props to override default props */
  backButtonProps?: ButtonProps

  /** The callback function that is triggered when the bcak button clicked */
  onBack?: React.MouseEventHandler
}

/**
 * Title bar with bcak button
 */
function TitleBar({ className, style, title, suffix, backButtonProps, onBack, ...restProps }: Props): JSX.Element {
  const navigate = useNavigate()

  const handleBack = (e: React.MouseEvent) => {
    // go back
    navigate(-1)
    onBack?.(e)
  }

  return (
    <div className={cn(styles.container, className)} style={style} {...restProps}>
      <div className={styles.prefix}>
        <Button type="text" icon={<span className="iconfont icon-leftarrowsmall" />} onClick={handleBack} {...backButtonProps} />
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.suffix}>{suffix}</div>
    </div>
  )
}

export default TitleBar
