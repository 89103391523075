import Styles from "./index.module.css"
import BaseButton from "@/components/Common/BaseButton"
import { useLocation, useNavigate } from "react-router-dom"
function NotFound() {
  const navigate = useNavigate()
  return (
    <div className={Styles.NotFound}>
      <h1>Sorry,Page Not Found !</h1>
      <BaseButton
        onClick={() => {
          navigate("/")
        }}
        color={"black"}
        shape={"round"}
      >
        Back to My Picks
      </BaseButton>
    </div>
  )
}
export default NotFound
