/* eslint-disable @typescript-eslint/no-empty-function */
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/es/integration/react"

import store, { persistor } from "./redux/store/index"
import AppRouter from "./router"

function App() {
  if (process.env.NODE_ENV === "production") {
    global.console.info = () => {}
    global.console.warn = () => {}
    global.console.debug = () => {}
    global.console.error = () => {}
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppRouter />
      </PersistGate>
    </Provider>
  )
}

export default App
