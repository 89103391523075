import { Button, Input, message } from "antd"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

import BaseButton from "@/components/Common/BaseButton"
import Dialog from "@/components/Common/Dialog/index"
import { editUser } from "@/services/pick"

import { setCurrentUser, updateCurrentUser } from "../../../redux/app/actions"
import { login } from "../../../services/account"
import { getAutoLogin, setAutoLoginInfo } from "../../../utils/authority"
import Styles from "./index.module.css"

function UserNameForm(props: any) {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [logging, setLogging] = useState(false)
  const [nickNameVisible, setNickNameVisible] = useState(false)
  const [nickName, setNickName] = useState("")

  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  const handleLogin = async () => {
    setLogging(true)
    const rep = await login({ username, password })
    if (rep && rep.token && rep.shopify_token) {
      // submit shopify multipass request
      setAutoLoginInfo(rep)
      props.dispatch(setCurrentUser(rep))
    } else {
      message.warn("login failed: " + rep.message)
      setLogging(false)
      return
    }
    setLogging(false)

    if (!rep.nameCheck) {
      setNickNameVisible(true)
    } else {
      gotoHomePage()
    }
  }

  const gotoHomePage = () => {
    navigate(searchParams.get("redirect") || _.get(location, ["state", "next_url"]) || "/Pick")
  }

  const confirmNickName = () => {
    const sendBody = {
      name: nickName,
    }
    editUser(sendBody).then((newUserInfo) => {
      if (newUserInfo) {
        message.success("Nickname added!")
        closeNickNameModal()
        props.dispatch(updateCurrentUser(newUserInfo))
        gotoHomePage()
      }
    })
  }

  const closeNickNameModal = () => {
    setNickNameVisible(false)
  }

  return (
    <div>
      <div className={Styles.loginContainer}>
        <div className={Styles.loginTitle}>Welcome to KiwiPick!</div>
        <div className={Styles.loginSection}>
          <div className={`${Styles.loginInput} ${Styles.userName}`}>
            <Input
              value={username}
              placeholder="Username"
              onChange={(event: any) => {
                setUsername(event.target.value)
              }}
            />
          </div>
          <div className={`${Styles.loginInput} ${Styles.passWord}`}>
            <Input
              value={password}
              placeholder="Password"
              type="password"
              onChange={(event: any) => {
                setPassword(event.target.value)
              }}
            />
          </div>

          <div className={Styles.handleBtnBox}>
            <BaseButton onClick={handleLogin} loading={logging} color={"black"} shape={"round"} disabled={!(!!username && !!password)}>
              Log In
            </BaseButton>
          </div>
        </div>
      </div>

      <Dialog title=" Nickname Required" visible={nickNameVisible} closeModal={closeNickNameModal} maskClosable>
        <div className={Styles.nicknameContainer}>
          <p className={Styles.nicknameTit}>Please enter a nickname for your pickkiwi account</p>
          <Input
            placeholder="enter a nickname"
            value={nickName}
            onChange={(e) => {
              setNickName(e.target.value)
            }}
          />
          <div className={Styles.nickNameSubmit}>
            <Button onClick={confirmNickName}>confirm</Button>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default connect()(UserNameForm)
