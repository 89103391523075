import "./index.css"

import { Image, Upload } from "antd"
import React from "react"

import { presignedUrl } from "../../../../services/pick"
import Common from "../../../../utils/common"
import Styles from "./index.module.css"

class UploadImages extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      fileList: [],
      showErr: false,
      dropEnter: false,
    }
  }

  clickUpload = () => {
    const uploadDom: any = document.querySelector("#uploadBtnHide")
    uploadDom.click()
  }

  showErr = () => {
    Common.toast("Please upload at least one image.", "warn")
    // this.setState({ showErr: true })
  }

  clickUpLoadImg = async (event: any) => {
    const { fileList } = this.state
    Common.showLoading("Uploading image, please wait...")
    const s3Url = await presignedUrl().then((res) => {
      if (res) {
        return res.item
      }
    })

    const imgsUrl = await fetch(s3Url, {
      method: "PUT",
      headers: {
        "Content-Type": "image/png",
      },
      body: event.file,
    }).then((res) => {
      if (res.url) {
        return res.url.split("?")[0]
      }
    })

    fileList.push(imgsUrl)

    Common.hideLoading()
    this.setState({
      fileList,
      showErr: false,
    })
  }

  drapUploadImg = (event: any) => {
    for (let file of event) {
      this.clickUpLoadImg({ file })
    }
  }

  handleChange = (uploadType: any, event: any) => {
    if (uploadType === "click") {
      this.clickUpLoadImg(event)
    } else if (uploadType === "drap") {
      this.drapUploadImg(event)
    }
  }

  removeFile = (file: any) => {
    let { fileList } = this.state
    fileList = fileList.filter((_file: any) => _file !== file)
    this.setState({
      fileList,
    })
  }

  imgDrap = (type: any, e: any) => {
    switch (type) {
      case "over":
        e.preventDefault()
        break
      case "enter":
        this.setState({
          dropEnter: true,
        })
        break
      case "leave":
        let obj = e.toElement || e.relatedTarget
        // 判断子元素是否在父元素之内

        if (obj && obj.parentNode && obj.parentNode.id === "uploadImagMain") {
          return
        }
        this.setState({
          dropEnter: false,
        })
        break
      case "done":
        e.preventDefault()
        this.setState({
          dropEnter: false,
        })
        this.handleChange("drap", e.dataTransfer.files)
        break
    }
  }

  render() {
    const { fileList, showErr, dropEnter } = this.state
    return (
      <>
        {/* <h3 style={{ fontWeight: 600 }}>
          Upload Image(s) <span className={Styles.require}>*</span>
        </h3> */}
        <div className={`${Styles.uploadImagesMain} uploadImagesMain`}>
          <h3 style={{ fontWeight: 600 }}>{fileList.length} Images</h3>

          <div
            id="uploadImagMain"
            className={`${Styles.uploadImagmesContent} ${dropEnter ? Styles.imageEnter : " "}`}
            onDrop={this.imgDrap.bind(this, "done")}
            onDragOver={this.imgDrap.bind(this, "over")}
            onDragEnter={this.imgDrap.bind(this, "enter")}
            onDragLeave={this.imgDrap.bind(this, "leave")}
          >
            <span className={Styles.dropText}>
              <span className="iconfont icon-share1" /> Drop files here or
            </span>
            <span onClick={this.clickUpload} className={`${Styles.uploadBtn} ant-btn`}>
              Browse Device
            </span>
          </div>
          {fileList.map((file: any, index: any) => (
            <div className={Styles.imgItem} key={index}>
              <span className={`${Styles.removeIcon} iconfont icon-exit`} onClick={this.removeFile.bind(this, file)}></span>
              <Image height={99} src={file} />
            </div>
          ))}

          <Upload listType="picture-card" customRequest={this.handleChange.bind(event, "click")}>
            <div className={Styles.uploadBtnHide} id="uploadBtnHide">
              Hide Add Images
            </div>
          </Upload>
          {showErr && <p className={Styles.errMsg}>Please upload at least one image.</p>}
        </div>
      </>
    )
  }
}

export default UploadImages
