import React from "react"

import Container from "@/components/Common/Container"
import Header from "@/components/Common/Header"
import useMediaQuery from "@/hooks/useMediaQuery"

import Footer from "./components/Footer"
import LoginForm from "./components/LoginForm"
import Styles from "./index.module.css"

const Login = () => {
  const isMobile = useMediaQuery("(max-width: 800px)")

  return (
    <Container className={Styles.loginContainer}>
      <Header className={Styles.loginHeader} showButtons={false} />
      <LoginForm />
      {!isMobile && <Footer />}
    </Container>
  )
}

export default Login
